import React, { useState } from "react";
import { Box, Grid, Paper, Typography, Stack, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { InputField, StartAndEndDateFilter } from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import ApiManager from "../../../services/api-manager";
import { handleLoader, setToast } from "../../../store/reducer";

const Settings = () => {
  const { id } = useParams();
  usePageTitle("Settings");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({
    allow_manual_items: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const formattedEmails = formData?.email
    ?.split(",")
    .map((email) => email.trim());

  const validateEmails = (formattedEmails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const uniqueEmails = new Set();
    const duplicateEmails = [];
    const formErrors = { title: "" };

    for (const email of formattedEmails) {
      if (!emailRegex.test(email)) {
        formErrors.title = "Enter a Valid Email";
        setFormErrors(formErrors);
        return false;
      }

      if (uniqueEmails.has(email)) {
        duplicateEmails.push(email);
      }

      uniqueEmails.add(email);
    }

    if (duplicateEmails.length > 0) {
      formErrors.title = `Duplicate emails entered: ${duplicateEmails.join(
        ", ",
      )}`;
      setFormErrors(formErrors);
      return false;
    }

    setFormErrors({});
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(handleLoader(true));
    try {
      if (!formData.email.trim() || !formData.email === "") {
        setFormErrors({ title: "Must Fill the Field" });
        return;
      }
      if (!validateEmails(formattedEmails)) {
        return;
      }
      setFormErrors({});

      let { data } = await ApiManager(
        "post",
        `organizer/send-general-report-to-emails`,
        {
          emails: formData?.email,
          event_id: id,
        },
      );
      showMessage("success", data?.response);
      setFormData({});
    } catch (error) {
      showMessage("error", error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const generatePdfHandler = async () => {
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager(
        "get",
        `organizer/generate-general-report/${id}?start_date=${formData?.start_date}&end_date=${formData?.end_date}&start_time=${formData?.start_time}&end_time=${formData?.end_time}`,
      );
      showMessage("success", data?.response);
    } catch (error) {
      showMessage("error", error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  console.log(formData);
  return (
    <Box m={{ sm: 2 }}>
      <Typography variant="h5">Settings</Typography>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        my={2}
        gap={2}
        justifyContent="space-between"
      >
        <Stack direction="row" gap={1} flexWrap="wrap">
          <Button startIcon={<FileDownloadIcon />} onClick={generatePdfHandler}>
            Generate PDF with Latest Stats
          </Button>
          <a
            href={`${process.env.REACT_APP_SAMPLE_API_KEY}download-general-overview-report/${id}`}
            target="_blank"
          >
            <Button startIcon={<FileDownloadIcon />}>Download PDF</Button>
          </a>
        </Stack>
        <Box>
          <StartAndEndDateFilter
            onFilterChange={(filterState) => {
              setFormData((prev) => ({ ...prev, ...filterState }));
            }}
            onRequestClear={() =>
              setFormData((prev) => ({
                ...prev,
                start_date: "",
                end_date: "",
                start_time: "",
                end_time: "",
              }))
            }
          />
        </Box>
      </Stack>

      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          autoCapitalize="off"
        >
          <Typography variant="h6">Add Emails</Typography>
          <Grid container columnSpacing={3} mt={1} rowSpacing={2}>
            <Grid item xs={12}>
              <Box>
                <InputField
                  label="Emails"
                  size="small"
                  name="email"
                  fullWidth
                  required
                  error={formErrors?.title}
                  value={formData?.email}
                  onChange={handleInputChange}
                  multiline
                  placeholder="ex: xyz@email.com, abc@email.com "
                />
              </Box>
            </Grid>
          </Grid>

          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Send Report
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
