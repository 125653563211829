import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import AddIcon from "@mui/icons-material/Add";

import {
  TablePagination,
  TableWrapper,
  StyledTableRow,
  StyledTableCell,
} from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import ApiManager from "../../../services/api-manager";
import { handleLoader, setToast } from "../../../store/reducer";
import calculateTime from "../../../hooks/calculate-time-diff";

function SpPaymentMethods() {
  usePageTitle("Payment Methods");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/payment-methods?page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.response?.detail);
      // setColumns(data?.columns);

      let timeDiff = calculateTime(
        data?.merchandiseProducts?.data[1]?.created_at,
      );
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(records);
  }, []);
  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (e, item) => {
    let _fd = {
      _method: "patch",
      status: e.target.checked === true ? "active" : "not-active",
    };
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager(
        "post",
        `superAdmin/payment-methods/${item?.payment_method_id}`,
        _fd,
      );
      setRecords((prev) =>
        prev?.map((obj) => {
          if (obj.payment_method_id === item.payment_method_id) {
            return { ...obj, active: e.target.checked };
          }
          return obj;
        }),
      );
      console.log(item);
      showMessage("success", data?.response);
    } catch (error) {
      // console.log(error);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const renderRow = (item) => (
    <StyledTableRow key={item?.payment_method_id}>
      <StyledTableCell>{item?.payment_method_id}</StyledTableCell>
      <StyledTableCell>{item?.title}</StyledTableCell>
      <StyledTableCell>{item?.type}</StyledTableCell>
      <StyledTableCell>
        <img
          src={item?.image_url}
          width="45px"
          height="45px"
          alt="product.png"
        />
      </StyledTableCell>
      <StyledTableCell>{Number(item?.fees_percentage)}%</StyledTableCell>
      <StyledTableCell>{Number(item?.fix_amount)}</StyledTableCell>
      <StyledTableCell>
        <FormControlLabel
          control={
            <Switch
              checked={item?.active}
              onChange={(e) => handleStatus(e, item)}
            />
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Tooltip title="Edit" placement="top">
            <IconButton
              color="info"
              onClick={() =>
                navigate(
                  `/admin/update-payment-methods/${item?.payment_method_id}`,
                )
              }
            >
              <CreateRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );

  // const handleCsvUpload = async (event) => {
  //     if (!event.target.files.length) {
  //         return;
  //     }
  //     let ext = event.target.files[0].name.split(".");
  //     if (ext[1] !== "csv") {
  //         return dispatch(
  //             setToast({
  //                 type: "error",
  //                 message: "Please select only .csv extension file.",
  //             }),
  //         );
  //     }
  //     try {
  //         dispatch(handleLoader(true));
  //         let _fd = new FormData();
  //         _fd.append("file", event.target.files[0]);
  //         let { data } = await ApiManager("post", "superAdmin/mp/upload-csv", _fd, {
  //             "Content-Type": "multipart/form-data",
  //         });
  //         showMessage("success", data);
  //         if (page === 1) {
  //             getData();
  //         } else {
  //             setPage(1);
  //         }
  //     } catch (error) {
  //         showMessage("error", error?.response?.data?.error?.message);
  //     } finally {
  //         dispatch(handleLoader(false));
  //     }
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
            Payment Methods
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ sm: "row", xs: "column" }} my={2}>
            <Button
              onClick={() => navigate("/admin/add-payment-methods")}
              startIcon={<AddIcon />}
            >
              Add Payment Methods
            </Button>
            {/* <Button
              startIcon={<UploadIcon />}
              onClick={() => document.getElementById("myFileInput").click()}
            >
              File Upload
            </Button>
            <input
              type="file"
              id="myFileInput"
              accept=".csv"
              style={{
                display: "none",
              }}
              onChange={handleCsvUpload}
            />
            <ExportBtn
              path={`reports-admin-merchandise-products-csv?`}
              data={columns}
            /> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TableWrapper
            pagination={
              <TablePagination
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                perPage={records?.per_page || 0}
                total={records?.total || 0}
              />
            }
            spanTd={thLabels.length}
            isLoading={isLoading}
            isContent={records?.length || false}
            thContent={
              <>
                {thLabels.map((v) => (
                  <TableCell key={v}>{v}</TableCell>
                ))}
              </>
            }
          >
            {records?.map((item, _) => renderRow(item))}
          </TableWrapper>
        </Grid>
      </Grid>
    </>
  );
}

export default SpPaymentMethods;

const thLabels = [
  "Payment Method Id",
  "Title",
  "Type",
  "Image",
  "Fees Percentage",
  "Fix Amount",
  "Active",
  "Action",
];
