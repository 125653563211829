import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  FormControl,
  OutlinedInput,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

import {
  CustomAutoComplete,
  FilePicker,
  InputField,
  SelectBox,
} from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";

const SpAddHomePageBanner = () => {
  const [formData, setFormData] = useState({
    text: "",
    banner_image: null,
    banner_image_url: null,
    event_id: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [allEvents, setAllEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate(-1);
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  usePageTitle("Home Page Banner");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  let getAllEvents = async () => {
    try {
      setLoadingEvents(true);

      let { data } = await ApiManager(
        "get",
        "superAdmin/events?per_page=10000",
      );
      setAllEvents(
        data?.events?.data.map((each) => ({
          label: each.name,
          value: each.event_id,
          // key: each.event_id,
        })),
      );
    } catch (error) {
      console.log("🚀 ~ getAllEvents ~ error:", error);
    } finally {
      setLoadingEvents(false);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!formData?.image) {
      errors.image = "Banner image is required.";
      flag = false;
    }
    if (!formData?.text) {
      errors.text = "Banner text is required";
      flag = false;
    }
    setFormErrors(errors);
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      dispatch(handleLoader(true));

      let { data } = await ApiManager(
        "post",
        "superAdmin/home-page-banners",
        formData,
        {
          "Content-Type": "multipart/form-data",
        },
      );
      setFormData({});
      showMessage("success", data?.response?.message);
    } catch (error) {
      setFormErrors(errorsSetter(error));
      showMessage("error", error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <form onSubmit={handleSubmit} autoCapitalize="off" autoComplete="off">
          <Typography variant="h5">Add Home Banner</Typography>
          <Grid container spacing={2} my={1}>
            <Grid item xs={12} lg={6}>
              <SelectBox
                label="Events"
                items={allEvents}
                onChange={handleInputChange}
                error={formErrors?.event_id}
                helperText={formErrors?.event_id}
                value={formData?.event_id}
                name="event_id"
              />
              {/* <CustomAutoComplete
                options={allEvents}
                label="Events"
                onChange={handleInputChange}
                error={formErrors?.event_id}
                helperText={formErrors?.event_id}
                value={formData?.event_id}
                name="event_id"
                required
                isOptionEqualToValue={(options, selectedOption) => {
                  console.log("🚀 ~ SpAddHomePageBanner ~ selectedOption:", selectedOption, options)
                  return options === selectedOption;
                }}
                loading={loadingEvents}
              /> */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputField
                label="Banner Text"
                onChange={handleInputChange}
                value={formData?.text}
                name="text"
                error={formErrors?.text}
              />
            </Grid>
            <Grid item xs={12}>
              <FilePicker
                labelTop="Banner image"
                name="banner_image"
                _id="banner_image"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    image: file,
                    banner_image_url: file && URL?.createObjectURL(file),
                  }));
                }}
                imageUrl={formData?.banner_image_url}
                boxWidth="100%"
                boxHeight="100%"
                boxMinHeight={{ sm: "600px", xs: "300px" }}
                boxMaxHeight="1000px"
                helperText="Recommended size 1920/1080px"
                error={formErrors?.image}
              />
            </Grid>
          </Grid>

          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={7}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button variant="contained" color="error" onClick={goBack}>
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default SpAddHomePageBanner;
