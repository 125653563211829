import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  Button,
  Fab,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  ConfirmationModal,
  InputField,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../component";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";
import { useDispatch, useSelector } from "react-redux";
import { handleLoader, setToast } from "../../../store/reducer";
import errorsSetter from "../../../helpers/error-setter";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const SpEvents = () => {
  usePageTitle("Organizer Events");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [deleteRecords, setDeleteRecords] = useState([]);
  const [modalState, setModalState] = useState(false);
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const { user } = useSelector((state) => state.storeReducer);
  let isPartner = user?.role === "partner";

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        isPartner
          ? `partner/events?event_organizer=${id}&page=${page}&per_page=${rowsPerPage}`
          : `superAdmin/events?event_organizer=${id}&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.events);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkAllRows = (e) => {
    const { checked } = e.target;
    if (checked) {
      setDeleteRecords(() => {
        return records?.data.map((item) => item?.event_id);
      });
    } else {
      setDeleteRecords([]);
    }
  };

  const checkRow = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      setDeleteRecords((prev) => [...prev, name]);
    } else {
      const filteredRecords = deleteRecords.filter((value) => value !== name);
      setDeleteRecords(filteredRecords);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeModal = () => {
    setModalState(false);
  };

  const deleteBulk = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager(
        "delete",
        `superAdmin/events?password=${password}&${deleteRecords
          .map((record, index) => `ids[]=${record}`)
          .join("&")}`,
      );
      showMessage("success", data);
      if (page === 1) {
        getData();
      } else {
        setPage(1);
      }
      setDeleteRecords([]);
      closeModal();
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      }
      if (error?.response?.status === 400) {
        showMessage("error", error?.response?.data);
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = ["ID", "Name", "Event Start Date", "Action"];

  return (
    <>
      <Box>
        <Fab size="small" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Organizer Events
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          mb={2}
          alignItems="flex-end"
        >
          <Button
            variant="outlined"
            disabled={!deleteRecords.length}
            onClick={() => setModalState(true)}
          >
            Delete
          </Button>
        </Stack>
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length + 1}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              <TableCell>
                <Checkbox
                  checked={deleteRecords.length === records?.data?.length}
                  onChange={checkAllRows}
                  disabled={Boolean(!records?.data?.length) || false}
                />
              </TableCell>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>
                <Checkbox
                  checked={
                    deleteRecords.findIndex(
                      (value) => value === item?.event_id,
                    ) >= 0
                      ? true
                      : false
                  }
                  name={item?.event_id}
                  onChange={checkRow}
                />
              </StyledTableCell>
              <StyledTableCell>{item?.event_id}</StyledTableCell>
              <StyledTableCell>{item?.name}</StyledTableCell>
              <StyledTableCell>
                {moment(item?.start_date).format(dateFormat)}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Button
                    type="button"
                    startIcon={<PreviewIcon />}
                    onClick={() =>
                      navigate(`/event/${item?.event_id}/management/overview`)
                    }
                    sx={{ textTransform: "none" }}
                    children="View"
                  />
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
      <ConfirmationModal hideFooter open={modalState} handleClose={closeModal}>
        <Box component="form" onSubmit={deleteBulk}>
          <Box component={Stack} spacing={2}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: { md: 22, xs: 18 },
              }}
              children="Confirm"
            />
            <InputField
              type="password"
              label="Password"
              name="password"
              required
              error={formErrors?.password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Typography sx={{ color: "#333", fontSize: 12 }}>
              <strong>Note:</strong>This action cannot be undo.
            </Typography>
            <Stack spacing={1}>
              <Button variant="contained" type="Submit" color="error">
                Confirm
              </Button>
              <Button
                variant="contained"
                type="button"
                color="info"
                onClick={() => {
                  setPassword("");
                  closeModal();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </ConfirmationModal>
    </>
  );
};

export default SpEvents;
