// import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom';
// import ApiManager from '../../../services/api-manager';

// const SpPayoutDetail = () => {
//     const { id } = useParams()

//     const getData = async () => {
//         try {
//             // setIsLoading(true);
//             let { data } = await ApiManager(
//                 "get",
//                 `superAdmin/get-payout/${id}`,
//             );
//             console.log("🚀 ~ getData ~ t:", data)
//             // setRecords(data?.response?.detail);
//         } catch (error) {
//             console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
//         }
//     };

//     useEffect(() => {
//         getData();
//     }, []);

//     return (
//         <div>SpPayoutDetail</div>
//     )
// }

// export default SpPayoutDetail

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ApiManager from "../../../services/api-manager";
import Utils from "../../../utils/utils";
import errorsSetter from "../../../helpers/error-setter";
import { FilePicker, InputField, SelectBox } from "../../../component";
import { setToast } from "../../../store/reducer";

const SpPayoutDetail = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    payout: {
      status: "failed",
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const [review, setReview] = useState("");
  const [media, setMedia] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const { user } = useSelector((state) => state.storeReducer);
  const [sendLoading, setSendLoading] = useState({
    review: false,
    media: false,
    updateLoading: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const getData = async () => {
    try {
      setLoading(true);
      let { data } = await ApiManager("get", `superAdmin/get-payout/${id}`);
      setData(data?.response?.detail);
    } catch (error) {
      showMessage("error", "Something went wrong please try again letter");
    } finally {
      setLoading(false);
    }
  };

  const sendReview = async (e) => {
    e.preventDefault();
    try {
      setSendLoading({ ...sendLoading, review: true });
      // let response = await ApiManager("post", `superAdmin/store-event-payout-review/${id}/${data?.payout?.event_payout_id}`, { review },);
      let response = await ApiManager(
        "post",
        `superAdmin/store-event-payout-review/${data?.payout?.event_info?.event_id}/${data?.payout?.event_payout_id}`,
        { review },
      );
      showMessage("success", response?.data?.response?.message);
      setData((pre) => ({
        ...pre,
        payout: {
          ...pre?.payout,
          payout_reviews: [
            ...pre?.payout?.payout_reviews,
            response?.data?.response?.detail,
          ],
        },
      }));
      setReview("");
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      setSendLoading({ ...sendLoading, review: false });
    }
  };

  // const updatePayoutDetail = async (e) => {
  //     e.preventDefault();
  //     try {
  //         if (formData?.extra_deduction_amount >= data?.payout?.final_amount) {
  //             showMessage("error", "This amount could not be greater than the final amount!");
  //             return;
  //         }
  //         setSendLoading({
  //             ...sendLoading, updateLoading: true
  //         })
  //         let response = await ApiManager("post", `superAdmin/update-payout/${data?.payout?.event_payout_id}`, formData,);
  //         showMessage("success", response?.data?.response?.message);

  //     } catch (error) {
  //         if (error?.response?.status === 422) {
  //             setFormErrors(errorsSetter(error));
  //         } else {
  //             showMessage("error", error?.response?.data?.error?.message);
  //         }
  //     } finally {
  //         setSendLoading({
  //             ...sendLoading, updateLoading: false
  //         })
  //         getData()
  //     }
  // };

  const updatePayoutDetail = async (e) => {
    e.preventDefault();
    try {
      if (+formData?.extra_deduction_amount >= +data?.payout?.final_amount) {
        showMessage(
          "error",
          "This amount could not be greater than the final amount!",
        );
        return; // Stop further execution
      }
      setSendLoading({
        ...sendLoading,
        updateLoading: true,
      });
      let response = await ApiManager(
        "post",
        `superAdmin/update-payout/${data?.payout?.event_payout_id}`,
        formData,
      );
      showMessage("success", response?.data?.response?.message);
      getData();
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      setSendLoading({
        ...sendLoading,
        updateLoading: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setFormData({
      extra_deduction_amount: data?.payout?.extra_deduction_amount,
      status: data?.payout?.status,
    });
  }, [data]);

  const sendMedia = async (e) => {
    e.preventDefault();
    try {
      if (!media?.logo) {
        setFormErrors({ image: "Image is required" });
        return;
      }
      setFormErrors({});
      setSendLoading({ ...sendLoading, media: true });
      let response = await ApiManager(
        "post",
        `superAdmin/store-event-payout-media/${data?.payout?.event_info?.event_id}/${data?.payout?.event_payout_id}`,
        { image: media?.logo },
        {
          "Content-Type": "multipart/form-data",
        },
      );
      showMessage("success", response?.data?.response?.message);
      setData((pre) => ({
        ...pre,
        payout: {
          ...pre?.payout,
          payout_medias: [
            ...pre?.payout?.payout_medias,
            response?.data?.response?.detail,
          ],
        },
      }));
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      setSendLoading({ ...sendLoading, media: false });
    }
  };

  return (
    <>
      <Container>
        {/* <HeadingWithBackBtn variant="h2" backPath={-1}>Payout Details</HeadingWithBackBtn> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
            Payout Details
          </Typography>
          {data?.payout?.status !== "completed" &&
            data?.payout?.status !== "failed" && (
              <LoadingButton
                loading={sendLoading?.updateLoading}
                variant="contained"
                onClick={updatePayoutDetail}
              >
                Update
              </LoadingButton>
            )}
        </Stack>
        <Grid container spacing={2} sx={{ minHeight: "330px" }}>
          <Grid item xs={12} lg={12 / 2}>
            {!loading ? (
              <Paper sx={{ p: 3, height: 1 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Event Name"
                  my={1}
                />
                <Typography
                  children={data?.payout?.event_info?.name}
                  variant="body2"
                  my={1}
                />
                <Divider />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Total Amount"
                  my={1}
                />
                <Typography
                  children={
                    data?.payout?.total_amount +
                    " " +
                    data?.payout?.event_info?.currency?.currency_code
                  }
                  variant="body2"
                  my={1}
                />
                <Divider />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Final Amount"
                  my={1}
                />
                <Typography
                  children={
                    data?.payout?.final_amount +
                    " " +
                    data?.payout?.event_info?.currency?.currency_code
                  }
                  variant="body2"
                  my={1}
                />
                <Divider />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Tax Percentage "
                  my={1}
                />
                <Typography
                  children={data?.payout?.tax_percentage + "%"}
                  variant="body2"
                  my={1}
                />
              </Paper>
            ) : (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ borderRadius: 1 }}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={12 / 2} component="form">
            {!loading ? (
              <Paper sx={{ p: 3, height: 1 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Status"
                  my={1}
                />
                {(data?.payout?.status !== "completed" ||
                  !(data?.payout?.status !== "failed")) && (
                  <SelectBox
                    items={[
                      { label: "Completed", value: "completed" },
                      { label: "Failed", value: "failed" },
                      { label: "Payout Requested", value: "payout_requested" },
                    ]}
                    label="Status"
                    name="status"
                    required
                    value={formData?.status}
                    onChange={handleInputChange}
                    error={formErrors?.status}
                  />
                )}

                {(data?.payout?.status === "completed" ||
                  data?.payout?.status === "failed") && (
                  <Typography
                    children={
                      data?.payout?.status === "payout_requested"
                        ? "Payout Requested"
                        : Utils.capitalize(data?.payout?.status)
                    }
                    variant="body2"
                    my={1}
                    sx={{ wordWrap: "break-word" }}
                  />
                )}
                <Divider />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Extra Deduction Amount"
                  my={1}
                />
                {(data?.payout?.status === "completed" ||
                  data?.payout?.status === "failed") && (
                  <Typography
                    children={
                      data?.payout?.extra_deduction_amount +
                      " " +
                      data?.payout?.event_info?.currency?.currency_code
                    }
                    variant="body2"
                    my={1}
                  />
                )}
                {(data?.payout?.status !== "completed" ||
                  !(data?.payout?.status !== "failed")) && (
                  <InputField
                    sx={{ my: 1 }}
                    label="Enter Extra Deduction Amount"
                    type="number"
                    name="extra_deduction_amount"
                    onChange={handleInputChange}
                    value={formData?.extra_deduction_amount}
                    InputProps={{
                      // max: 1000,
                      endAdornment: (
                        <InputAdornment position="end">
                          {data?.payout?.event_info?.currency?.currency_code}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                <Divider />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Tax Amount"
                  my={1}
                />
                <Typography
                  children={
                    data?.payout?.tax_amount +
                    " " +
                    data?.payout?.event_info?.currency?.currency_code
                  }
                  variant="body2"
                  my={1}
                />
                <Divider />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  children="Bank Details"
                  my={1}
                />
                <Typography
                  children={data?.payout?.bank_details}
                  variant="body2"
                  my={1}
                />
                <Divider />
              </Paper>
            ) : (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ borderRadius: 1 }}
              />
            )}
          </Grid>
        </Grid>
        {!!data?.payout?.payout_reviews?.length && (
          <Box>
            <Typography variant="h5" fontWeight="SemiBold" sx={{ mt: 1 }}>
              Reviews
            </Typography>
            <Grid container spacing={1} sx={{ my: 1 }}>
              {data?.payout?.payout_reviews?.map((e) => {
                return (
                  <Grid item xs={12} md={12 / 2} lg={12 / 2} p={2}>
                    <Paper elevation={5} sx={{ p: 2, height: "100%" }}>
                      <Typography fontWeight="SemiBold">
                        {e?.from_id === user?.user_id ? "You" : "System Admin"}
                      </Typography>
                      <Typography sx={{ wordBreak: "break-word" }}>
                        {e?.review}
                      </Typography>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={8}>
            <Stack
              // sx={{ padding: "30px 0" }}
              justifyContent="center"
              alignItems="flex-start"
              gap={2}
              component="form"
              onSubmit={sendReview}
            >
              <Typography variant="h5" fontWeight="SemiBold">
                Submit Your Review
              </Typography>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                multiline
                rows={6}
                name="review"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                required
                error={formErrors?.review}
                helperText={formErrors?.review}
                placeholder="Submit Your Review"
              />
              <LoadingButton
                loading={sendLoading?.review}
                type="submit"
                variant="contained"
                fullWidth
              >
                Submit Review
              </LoadingButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              height={1}
              gap={2}
              justifyContent="space-between"
              onSubmit={sendMedia}
              component="form"
            >
              <Typography variant="h5" fontWeight="SemiBold">
                Submit Your Media
              </Typography>
              <FilePicker
                name="logo"
                _id="logo"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setMedia((prev) => ({
                    ...prev,
                    logo: file,
                    image_url: file && URL?.createObjectURL(file),
                  }));
                }}
                imageUrl={media?.image_url}
                error={formErrors?.image}
                boxHeight="135px"
                boxMaxHeight="200px"
                boxWidth="200px"
                required
              />
              <LoadingButton
                loading={sendLoading?.media}
                type="submit"
                variant="contained"
                fullWidth
              >
                Submit Media
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        {!!data?.payout?.payout_medias?.length && (
          <Box>
            <Typography variant="h5" fontWeight="SemiBold" sx={{ mt: 2 }}>
              Media Reviews
            </Typography>
            <Grid container spacing={2} sx={{ my: 0.5 }}>
              {data?.payout?.payout_medias?.map((e, i) => {
                return (
                  <Grid item xs={12} md={12 / 2} lg={12 / 4}>
                    <Paper elevation={5}>
                      <img src={e?.image_url} alt="" width="100%" />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
};

export default SpPayoutDetail;
