import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { ChartContainer } from "../../../../component";
import { Box, Tabs, Typography } from "@mui/material";
import Utils from "../../../../utils/utils";
import _ from "lodash";
import { BarChart } from "@mui/x-charts/BarChart";
import moment from "moment";

const ChartPanel = ({ data, symbol = "$" }) => {
  const [value, setValue] = React.useState("1");

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  // Manage Data for Graph

  const totalTicketValuesForGraph = _?.map(data?.ticket_graph, "total")?.map(
    (value) => Number(value),
  );
  const ticketDateForGraph = _?.map(data?.ticket_graph, "date");

  const revenueValuesForGraph = _?.map(data?.revenue_graph, "revenue")?.map(
    (value) => Number(value),
  );
  const revenueDateForGraph = _?.map(data?.revenue_graph, "order_date");

  const personValuesForGraph = _?.map(data?.in_person_graph, "total")?.map(
    (value) => Number(value),
  );
  const personDateForGraph = _?.map(data?.in_person_graph, "date");

  const attendeeValuesForGraph = _?.map(data?.attendee_graph, "total")?.map(
    (value) => Number(value),
  );
  const attendeeDateForGraph = _?.map(data?.attendee_graph, "date");

  const onlineTopupsDateForGraph = _?.map(data?.online_topup_graph, "date");
  const onlineTopupsValuesForGraph = _?.map(
    data?.online_topup_graph,
    "total",
  )?.map((value) => Number(value));

  const merchandiseForGraph = _?.map(data?.merchandiseGraph, "revenue")?.map(
    (value) => Number(value),
  );
  const merchandiseDateForGraph = _?.map(data?.merchandiseGraph, "order_date");

  return (
    <div>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            maxWidth: { xs: 280, sm: "100%" },
          }}
        >
          <Tabs
            onChange={onChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={value}
          >
            {tabsData(
              data?.tickets,
              data?.revenue,
              data?.attendees,
              data?.in_person_topups,
              data?.online_topups,
              data?.all_merchandises_orders_amount,
            ).map((item) => (
              <Tab
                key={item.value}
                label={
                  <React.Fragment>
                    <Typography
                      variant="subtitle2"
                      fontWeight="semiBold"
                      textTransform="none"
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      variant="h5"
                      fontWeight="Bold"
                      textTransform="none"
                    >
                      {[
                        "Revenue",
                        "In-Person-TopUp",
                        "Online-TopUp",
                        "Merchandise Sales",
                      ].includes(item?.label)
                        ? symbol
                        : ""}
                      {Utils.formatWithSuffix(item.amount)}
                    </Typography>
                  </React.Fragment>
                }
                value={item.value.toString()}
              />
            ))}
          </Tabs>
        </Box>
        <TabPanel value="1">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            <BarChart
              xAxis={[{ scaleType: "band", data: ticketDateForGraph }]}
              series={[
                {
                  data: data?.ticket_graph.length
                    ? totalTicketValuesForGraph
                    : [0],
                  stack: "A",
                  label: "Tickets",
                },
              ]}
              height={350}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />

            {/* <ChartContainer
              title="Attendee"
              labels={
                data?.ticket_graph &&
                data?.ticket_graph.map((item) => item?.date)
              }
              values={
                data?.ticket_graph &&
                data?.ticket_graph.map((item) => item?.total)
              }
            /> */}
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            <BarChart
              xAxis={[{ scaleType: "band", data: revenueDateForGraph }]}
              series={[
                {
                  data: data?.revenue_graph?.length
                    ? revenueValuesForGraph
                    : [0],
                  stack: "A",
                  label: "Revenue",
                },
              ]}
              height={350}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />

            {/* <ChartContainer
              title="Revenue"
              labels={
                data?.revenue_graph &&
                data?.revenue_graph.map((item) => item?.order_date)
              }
              values={
                data?.revenue_graph &&
                data?.revenue_graph.map((item) => item?.revenue)
              }
            /> */}
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            <BarChart
              xAxis={[{ scaleType: "band", data: attendeeDateForGraph }]}
              series={[
                {
                  data: data?.attendee_graph?.length
                    ? attendeeValuesForGraph
                    : [0],
                  stack: "A",
                  label: "Attendees",
                },
              ]}
              height={350}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />

            {/* <ChartContainer
              title="Admitted"
              labels={
                data?.attendee_graph &&
                data?.attendee_graph.map((item) => item?.date)
              }
              values={
                data?.attendee_graph &&
                data?.attendee_graph.map((item) => item?.total)
              }
            /> */}
          </Box>
        </TabPanel>
        <TabPanel value="4">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            <BarChart
              xAxis={[{ scaleType: "band", data: personDateForGraph }]}
              series={[
                {
                  data: data?.in_person_graph?.length
                    ? personValuesForGraph
                    : [0],
                  stack: "A",
                  label: "Person TopUps",
                },
              ]}
              height={350}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />

            {/* <ChartContainer
              title="Top-Up"
              labels={
                data?.in_person_graph &&
                data?.in_person_graph.map((item) => item?.date)
              }
              values={
                data?.in_person_graph &&
                data?.in_person_graph.map((item) => item?.total)
              }
            /> */}
          </Box>
        </TabPanel>
        <TabPanel value="5">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            <BarChart
              xAxis={[{ scaleType: "band", data: onlineTopupsDateForGraph }]}
              series={[
                {
                  data: data?.online_topup_graph?.length
                    ? onlineTopupsValuesForGraph
                    : [0],
                  stack: "A",
                  label: "Online TopUps",
                },
              ]}
              height={350}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />

            {/* <ChartContainer
              title="Top-Up"
              labels={
                data?.online_topup_graph &&
                data?.online_topup_graph.map((item) => item?.date)
              }
              values={
                data?.online_topup_graph &&
                data?.online_topup_graph.map((item) => item?.total)
              }
            /> */}
          </Box>
        </TabPanel>
        <TabPanel value="6">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            <BarChart
              xAxis={[{ scaleType: "band", data: merchandiseDateForGraph }]}
              series={[
                {
                  data: data?.merchandiseGraph?.length
                    ? merchandiseForGraph
                    : [0],
                  stack: "A",
                  label: "Merchandise Sales",
                },
              ]}
              height={350}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />

            {/* <ChartContainer
              title="Top-Up"
              labels={
                data?.merchandiseGraph &&
                data?.merchandiseGraph.map((item) => item?.order_date)
              }
              values={
                data?.merchandiseGraph &&
                data?.merchandiseGraph.map((item) => item?.revenue)
              }
            /> */}
          </Box>
        </TabPanel>
      </TabContext>
    </div>
  );
};

const tabsData = (att, rev, adm, top, online, mer) => {
  return [
    {
      label: "Tickets",
      amount: att || 0,
      value: 1,
    },
    {
      label: "Revenue",
      amount: rev || 0,
      value: 2,
    },
    {
      label: "Attendee",
      amount: adm || 0,
      value: 3,
    },
    {
      label: "In-Person-TopUp",
      amount: top || 0,
      value: 4,
    },
    {
      label: "Online-TopUp",
      amount: online || 0,
      value: 5,
    },
    {
      label: "Merchandise Sales",
      amount: mer || 0,
      value: 6,
    },
  ];
};

export default ChartPanel;
