import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import PreviewIcon from "@mui/icons-material/Preview";
import moment from "moment/moment";

import { useNavigate } from "react-router-dom";
import {
  ExportBtn,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../component";
import ApiManager from "../../services/api-manager";
import usePageTitle from "../../hooks/use-page-title";
import { useSelector } from "react-redux";
import QrCodeModal from "./access-control/event-tickets/qr-code-modal";
import QrCodeIcon from "@mui/icons-material/QrCode";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const Events = () => {
  usePageTitle("Events");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [qrCodeObj, setOrCodeObj] = useState(null);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `organizer/events?page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.events);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const thLabels = [
    "ID",
    "Name",
    "Currency",
    // "Venue",
    "Event Start Date",
    "Event End Date",
    "Action",
  ];

  const exportPath = `reports-events-csv?organizer_id=${user?.user_id}&`;

  const openModal = (item) => {
    let _obj = {
      id: item?.event_id,
    };
    let stringify = JSON.stringify(_obj);
    setOrCodeObj(stringify);
    setShowModal(true);
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Events
        </Typography>

        <Stack direction="row" m={2}>
          <Button
            onClick={() => navigate("/events/create-event")}
            startIcon={<ControlPointIcon />}
          >
            Create Event
          </Button>
          <ExportBtn data={columns} path={exportPath} />
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.event_id}</StyledTableCell>
              <StyledTableCell>{item?.name}</StyledTableCell>
              <StyledTableCell>
                {item?.currency?.currency_symbol}
              </StyledTableCell>
              {/* <StyledTableCell>{item?.venue?.name}</StyledTableCell> */}
              <StyledTableCell>
                {moment(item?.start_date).format(dateFormat)}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.end_date).format(dateFormat)}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Button
                    type="button"
                    startIcon={<PreviewIcon />}
                    onClick={() =>
                      navigate(`/event/${item?.event_id}/management/overview`)
                    }
                    sx={{ textTransform: "none" }}
                    children="View"
                  />
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(`/events/update-event/${item?.event_id}`)
                      }
                    >
                      <CreateRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Qr Code" placement="top" color="info">
                    <IconButton type="button" onClick={() => openModal(item)}>
                      <QrCodeIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
      <QrCodeModal
        open={showModal}
        close={() => setShowModal(false)}
        ticketObj={qrCodeObj}
      />
    </div>
  );
};

export default Events;
