import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Stack, TableCell } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { InputField, TablePagination, TableWrapper } from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import ApiManager from "../../../services/api-manager";
import TableDataRow from "./table-data-row";

const EventUserManagement = () => {
  usePageTitle("User Management");
  const { loggedEvent } = useSelector((state) => state.storeReducer);
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [search, setSearch] = useState("");
  const [searchCalled, setSearchCalled] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/devices?page=${page}&per_page=${rowsPerPage}&event_id=${id}&search=${_search}`,
        // `${_role}/devices?page=${page}&per_page=${rowsPerPage}&event_id=${id}&search=${encodeURIComponent(
        //   JSON.stringify(_search),
        // )}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const thLabels = [
    "Email",
    "Tag Open ID",
    "Time Difference b/w Last Synced",
    "Last Time Synced",
    "Status",
    "Total cash collected",
    "Total credit card collected",
    "Total cash reported",
    "Total credit card reported",
    "Action",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Device User Management
        </Typography>

        {!_isAdmin && (
          <Stack direction="row" m={2} justifyContent="space-between">
            <Button
              startIcon={<ControlPointIcon />}
              onClick={() =>
                navigate(`/event/${id}/management/user-management/create-user`)
              }
            >
              Add Device User
            </Button>

            <Stack direction="row" gap={2}>
              <InputField
                label="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Name/Email"
              />

              {!!search && (
                <Button
                  onClick={() => {
                    if (page === 1) {
                      getData(search);
                    } else {
                      setPage(1);
                    }
                    setSearchCalled(true);
                  }}
                  variant="contained"
                  sx={{ color: "white" }}
                >
                  Search
                </Button>
              )}
              {searchCalled && (
                <Button
                  onClick={() => {
                    setSearch("");
                    if (page === 1) {
                      getData();
                    } else {
                      setPage(1);
                    }
                    setSearchCalled(false);
                  }}
                  variant="contained"
                  color="error"
                >
                  Clear
                </Button>
              )}
            </Stack>
          </Stack>
        )}

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  bgcolor: "white",
                  outline: "1px solid #E7E7E7",
                }}
              >
                Operator Name
              </TableCell>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
        >
          {records?.data.map((item, i) => (
            <TableDataRow item={item} isAdmin={_isAdmin} />
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default EventUserManagement;
