import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

import { InputField } from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

const SpCreateEventType = () => {
  const eventTypeId = useParams()?.id;
  usePageTitle(eventTypeId ? "Update" : "Add" + " Event Type");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate("/admin/event-types");
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (eventTypeId) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/event-types/${eventTypeId}`,
          );
          setFormData({
            title: data?.response?.detail?.title,
            active: data?.response?.detail?.active,
          });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [eventTypeId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validator = () => {
    let flag = false;
    let error = {};
    if (!Utils.hasOnlyNonNumericCharacters(formData.title)) {
      error.title = "Title must only contain alphabets";
      flag = true;
    }
    setFormErrors((prev) => ({ ...prev, ...error }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator()) return;
    try {
      dispatch(handleLoader(true));
      let _url = eventTypeId
        ? `superAdmin/event-types/${eventTypeId}`
        : "superAdmin/event-types";
      let fd = {
        ...formData,
        status: formData?.active ? "active" : "not-active",
      };
      if (!!eventTypeId) {
        fd._method = "patch";
      }
      await ApiManager("post", _url, fd);
      showMessage(
        "success",
        `Event Type ${!!eventTypeId ? "updated" : "added"} successfully.`,
      );
      goBack();
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Title"
            name="title"
            required
            error={formErrors?.title}
            value={formData?.title}
            onChange={handleInputChange}
          />
        </Grid>
        {eventTypeId && (
          <Grid item sm={4} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(formData?.active)}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      active: e.target.checked,
                    }));
                  }}
                />
              }
              label="Status"
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {eventTypeId ? "Update" : "Add"} Event Type
          </Typography>
          {formInputs()}
          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button variant="contained" color="error" onClick={goBack}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpCreateEventType;
