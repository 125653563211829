import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  Chip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { Visibility } from "@mui/icons-material";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
} from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import Utils from "../../../utils/utils";

const SpPartner = () => {
  usePageTitle("Partner");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalState, setModalState] = useState(false);
  const [recordID, setRecordID] = useState(null);

  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/get-partners-list?per_page=${rowsPerPage}&page=${page}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const thLabels = [
    "Name",
    "Phone Number",
    "Country",
    "Organization Name",
    "Email",
    "City",
    "Actions",
  ];

  const renderRow = (item) => {
    return (
      <StyledTableRow key={item?.user_id}>
        <StyledTableCell>{`${item?.last_name} ${item?.first_name}`}</StyledTableCell>
        <StyledTableCell>{item?.phone_number}</StyledTableCell>
        <StyledTableCell>{item?.country}</StyledTableCell>
        <StyledTableCell>{item?.organization_name}</StyledTableCell>
        <StyledTableCell>{item?.email}</StyledTableCell>
        <StyledTableCell>{item?.city}</StyledTableCell>
        <StyledTableCell>
          <IconButton
            sx={{ color: "primary.main" }}
            onClick={() =>
              navigate(`/admin/partner/update-partner/${item?.user_id}`)
            }
          >
            <Visibility />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Partner
        </Typography>
        <Stack direction={{ sm: "row", xs: "column" }} my={2}>
          <Button
            onClick={() => navigate("/admin/partner/create-partner")}
            startIcon={<AddIcon />}
          >
            Add Partner
          </Button>
        </Stack>
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={!!records?.data?.length}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, _) => renderRow(item))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default SpPartner;
