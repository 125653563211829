import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  FormControlLabel,
  Switch,
  Modal,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";
import { Visibility } from "@mui/icons-material";

const SpFaqs = () => {
  usePageTitle("Faqs");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/admin-faqs?per_page=${rowsPerPage}&page=${page}`,
      );
      setRecords(data?.response?.detail?.data);
      setTotal(data?.response?.detail?.total);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const handleStatus = async (e, item) => {
    let _fd = {
      _method: "patch",
      status: e.target.checked === true ? "active" : "not-active",
    };
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager(
        "post",
        `superAdmin/admin-faqs/${item?.admin_faq_id}`,
        _fd,
      );
      setRecords((prev) =>
        prev?.map((obj) => {
          if (obj.admin_faq_id === item.admin_faq_id) {
            return { ...obj, active: e.target.checked };
          }
          return obj;
        }),
      );

      showMessage("success", data?.response);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleDelete = async (id, index) => {
    console.log("🚀 ~ handleDelete ~ index:", index);
    setIsLoading(true);
    try {
      await ApiManager("delete", `superAdmin/admin-faqs/${id}`);
      let updatedRecords = [...records];
      updatedRecords.splice(index, 1);
      setRecords(updatedRecords);

      showMessage("success", "Record deleted successfully");
    } catch (err) {
      console.log(err);
      showMessage("error", "Failed to delete Faq");
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        Faqs
      </Typography>
      <Button
        sx={{ my: 2 }}
        startIcon={<AddIcon />}
        onClick={() => navigate("/admin/event-properties/create-faqs")}
      >
        Create Faq
      </Button>
      <TableWrapper
        spanTd={thLabels.length}
        isLoading={isLoading}
        isContent={records?.length || false}
        thContent={
          <>
            {thLabels.map((v) => (
              <TableCell key={v}>{v}</TableCell>
            ))}
          </>
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        total={total || 0}
        showPagination
      >
        {records?.length &&
          records?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.question}</StyledTableCell>
              <StyledTableCell>{item?.answer}</StyledTableCell>
              <StyledTableCell>{item?.order_number}</StyledTableCell>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item?.active}
                      onChange={(e) => handleStatus(e, item)}
                    />
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/admin/event-properties/update-faq/${item?.admin_faq_id}`,
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      onClick={() => handleDelete(item?.admin_faq_id, i)}
                    >
                      <DeleteForeverIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </TableWrapper>
    </Box>
  );
};

export default SpFaqs;

const thLabels = ["Question", "Answer", "Order No", "Status", "Action"];
