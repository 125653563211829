import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import nacl from "tweetnacl";

import {
  FilePicker,
  InputDate,
  InputField,
  PasswordFiled,
  SelectBox,
} from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import Utils from "../../../../utils/utils";
import usePageTitle from "../../../../hooks/use-page-title";
import moment from "moment";

function arrayBufferToHex(buffer) {
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x) => ("00" + x.toString(16)).slice(-2))
    .join("");
}

const SpCreateOrganizer = () => {
  const userID = useParams()?.id;
  usePageTitle(userID ? "Update" : "Create" + " Organizer");
  const [formData, setFormData] = useState({
    allow_third_party: 0,
  });
  const [record, setRecord] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const { user } = useSelector((state) => state.storeReducer);
  let isPartner = user?.role === "partner";

  useEffect(() => {
    async function init() {
      if (userID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            isPartner
              ? `partner/single/organizer/${userID}`
              : `superAdmin/single/organizer/${userID}`,
          );
          setFormData({
            ...data,
            ...{
              public_key: data?.api_token?.public_key,
              secret_key: data?.api_token?.secret_key,
            },
          });
          setRecord({ ...data });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [userID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!!formData?.phone_number && !Utils.isNumber(formData?.phone_number)) {
      errors.phone_number = "Should be a number";
      flag = false;
    } else if (
      !!formData?.phone_number &&
      !Utils.inRange(formData?.phone_number, 12)
    ) {
      errors.phone_number = "No more than 12 digits";
      flag = false;
    }
    if (!!formData?.email && !Utils.isValidEmail(formData?.email)) {
      errors.email = "Email format is invalid.";
      flag = false;
    }
    if (!!formData?.event_limit && !Utils.isNumber(formData?.event_limit)) {
      errors.event_limit = "Must be a number.";
      flag = false;
    }
    if (!Utils.hasOnlyNonNumericCharacters(formData.first_name)) {
      errors.first_name = "First name must only contain alphabets";
      flag = false;
    }
    if (!Utils.hasOnlyNonNumericCharacters(formData.last_name)) {
      errors.last_name = "Last name must only contain alphabets";
      flag = false;
    }

    // if (!(!!formData?.image) && !userID) {
    //     errors.image = "Image is required."
    //     flag = false;
    // }
    setFormErrors({ ...errors });
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(handleLoader(true));
        let _url = userID
          ? `superAdmin/update/organizer/${userID}`
          : "superAdmin/add/organizer";

        let _partnerUrl = userID
          ? `partner/update-organizer/${userID}`
          : "partner/store-organizer";

        let _obj = { ...formData };
        if (record?.email === _obj.email) {
          delete _obj.email;
        }
        let _fd = new FormData();
        for (const key in _obj) {
          if (Object.hasOwnProperty.call(_obj, key)) {
            _fd.append(key, _obj[key]);
          }
        }
        await ApiManager("post", isPartner ? _partnerUrl : _url, _fd, {
          "Content-Type": "multipart/form-data",
        });
        if (userID) {
          showMessage("success", "Organizer updated successfully.");
        } else {
          showMessage("success", "Organizer Added successfully.");
        }
        navigate(-1);
      } catch (error) {
        if (error?.response?.status === 422) {
          setFormErrors(errorsSetter(error));
        } else {
          showMessage("error", error?.response?.data?.error?.message);
        }
      } finally {
        dispatch(handleLoader(false));
      }
    }
  };

  const generateKeys = (e) => {
    const keyPair = nacl.sign.keyPair();
    const publicKeyHex = arrayBufferToHex(keyPair.publicKey).substring(0, 15);
    const privateKeyHex = arrayBufferToHex(keyPair.secretKey).substring(0, 15);

    setFormData((prev) => ({
      ...prev,
      ...{
        public_key: publicKeyHex,
        secret_key: privateKeyHex,
        allow_third_party: e.target.checked ? 1 : 0,
      },
    }));
  };

  const formInputs = () => {
    return (
      <>
        {isPartner && (
          <Stack direction="row" gap={6} my={2}>
            <Box>
              <FilePicker
                _id="picker1"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    organization_logo: file,
                    organization_logo_url: file && URL.createObjectURL(file),
                  }));
                }}
                labelTop="Organization Logo"
                error={formErrors?.organization_logo}
                imageUrl={formData?.organization_logo_url}
                title="Pick a file"
              />
            </Box>
            <Box>
              <FilePicker
                _id="picker2"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    profile_image: file,
                    image_url: file && URL.createObjectURL(file),
                  }));
                }}
                labelTop="Profile Image"
                error={formErrors?.profile_image}
                imageUrl={formData?.image_url}
                title="Pick a file"
              />
            </Box>
          </Stack>
        )}
        <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
          <Grid item sm={4} xs={12}>
            <InputField
              label="First Name"
              name="first_name"
              required
              error={formErrors?.first_name}
              value={formData?.first_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InputField
              label="Last Name"
              name="last_name"
              required
              error={formErrors?.last_name}
              value={formData?.last_name}
              onChange={handleInputChange}
            />
          </Grid>
          {!isPartner && (
            <Grid item sm={4} xs={12}>
              <Box>
                <SelectBox
                  fullWidth
                  items={[{ label: "Organizer", value: "organizer" }]}
                  label="Role"
                  name="role"
                  required
                  error={formErrors?.role}
                  value={formData?.role}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
          )}
          <Grid item sm={4} xs={12}>
            <InputField
              label="Phone Number"
              type="number"
              name="phone_number"
              required
              error={formErrors?.phone_number}
              value={formData?.phone_number}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InputField
              label="Email"
              type="email"
              name="email"
              disabled={!!userID || false}
              required
              error={formErrors?.email}
              value={formData?.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <PasswordFiled
              label="Password"
              name="password"
              required={userID ? false : true}
              error={formErrors?.password}
              value={formData?.password}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InputField
              label="Limit"
              name="event_limit"
              required
              type="number"
              error={formErrors?.event_limit}
              value={formData?.event_limit}
              onChange={handleInputChange}
            />
          </Grid>
          {isPartner && (
            <>
              <Grid item sm={4} xs={12}>
                <InputDate
                  label="Date of Birth"
                  error={formErrors?.dob}
                  value={moment(formData?.dob)}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, dob: val }))
                  }
                  required
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Organization Name"
                  name="organization_name"
                  required
                  error={formErrors?.organization_name}
                  value={formData?.organization_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Address"
                  name="address"
                  required
                  error={formErrors?.address}
                  value={formData?.address}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="City"
                  name="city"
                  required
                  error={formErrors?.city}
                  value={formData?.city}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="State"
                  name="state"
                  required
                  error={formErrors?.state}
                  value={formData?.state}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Country"
                  name="country"
                  required
                  error={formErrors?.country}
                  value={formData?.country}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Website Url"
                  name="website_url"
                  required
                  error={formErrors?.website_url}
                  value={formData?.website_url}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Facebook Url"
                  name="facebook_link"
                  required
                  error={formErrors?.facebook_link}
                  value={formData?.facebook_link}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="X Handle"
                  name="x_handle"
                  required
                  error={formErrors?.x_handle}
                  value={formData?.x_handle}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label="Organizer Bio"
                  name="organizer_bio"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  error={formErrors?.organizer_bio}
                  value={formData?.organizer_bio}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}

          <Grid item sm={4} xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={Boolean(formData?.allow_third_party)} />
              }
              label="Allow Third Party"
              name="allow_third_party"
              onChange={(e) => {
                generateKeys(e);
              }}
            />
          </Grid>

          {!!formData.allow_third_party && (
            <>
              <Grid item xs={12} sx={{ my: 1 }}>
                <Divider />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputField
                  label="Public key"
                  name="public_key"
                  error={formErrors?.public_key}
                  value={formData?.public_key}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputField
                  label="Secret key"
                  name="secret_key"
                  required
                  error={formErrors?.secret_key}
                  value={formData?.secret_key}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {userID ? "Update" : "Create"} Admin User
          </Typography>
          <Typography variant="body1">
            Create a user account to help manage your event and customer
          </Typography>
          {formInputs()}
          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpCreateOrganizer;
