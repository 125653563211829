import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import { InputField, SelectBox } from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import errorsSetter from "../../../helpers/error-setter";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpBtaConfig = () => {
  const currencyID = useParams()?.id;
  usePageTitle(currencyID ? "Update" : "Add" + " Currency");
  const [event, setEvent] = useState({});
  const [apiData, setApiData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [getFeesData, setGetFeesData] = useState(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    // event_payout_tax_percentage: "",
    service_fees: "",
  });

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getFees();
  }, []);

  const getFees = async () => {
    try {
      let { data } = await ApiManager("get", "superAdmin/get-fees");
      setFormData({
        event_payout_tax_percentage:
          data?.response?.detail?.event_payout_tax_percentage || "",
        service_fees: data?.response?.detail?.service_fees || "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const validator = () => {
  //   let flag = false;
  //   let error = {};
  //   if (!event) {
  //     error.event = "Event id required";
  //     flag = true;
  //   }
  //   setFormErrors((prev) => ({ ...prev, ...error }));
  //   return flag;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (validator()) return;
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager("post", "superAdmin/update-fees", {
        ...formData,
      });
      showMessage("success", data?.response);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12 / 3} md={12 / 4}>
            <TextField
              size="small"
              onChange={handleInputChange}
              name="event_payout_tax_percentage"
              label="Event Payout Tax Percentage"
              value={formData.event_payout_tax_percentage}
              error={formErrors?.event_payout_tax_percentage}
              type="number"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12 / 3} md={12 / 4}>
            <TextField
              size="small"
              onChange={handleInputChange}
              name="service_fees"
              label="Service Fee"
              type="number"
              value={formData.service_fees}
              error={formErrors?.service_fees}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            Update Payment Processing & Service Fees
          </Typography>
          {formInputs()}
          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Update
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpBtaConfig;
