import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import nacl from "tweetnacl";

import {
  FilePicker,
  InputField,
  PasswordFiled,
  SelectBox,
} from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import Utils from "../../../../utils/utils";
import usePageTitle from "../../../../hooks/use-page-title";
import { Visibility } from "@mui/icons-material";

const SpAwaitingForPublishDetails = () => {
  const awaitingId = useParams()?.awaitingId;
  usePageTitle("Awaiting For Publish Details");
  const [formData, setFormData] = useState({
    status: "approved",
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _path = "/admin/event-properties/awaiting-for-publish";
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!formData?.reason?.length && formData?.status === "rejected") {
      errors.reason = "Reason required";
      flag = false;
    }
    if (formData?.reason?.length < 5 && formData?.status === "rejected") {
      errors.reason = "Reason must be 5 characters long";
      flag = false;
    }

    console.log("🚀 ~ validate ~ errors:", errors);
    setFormErrors({ ...errors });
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(handleLoader(true));
        let _url = `superAdmin/approve-or-reject-publish-request/${awaitingId}`;
        await ApiManager("post", _url, formData);
        navigate(_path);
      } catch (error) {
        if (error?.response?.status === 422) {
          setFormErrors(errorsSetter(error));
        } else {
          showMessage("error", error?.response?.data?.error?.message);
        }
      } finally {
        dispatch(handleLoader(false));
      }
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            startIcon={<Visibility />}
            component="a"
            href={`https://cxevents.global/events/event-details/${awaitingId}`}
            target="_blank"
          >
            View event
          </Button>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <SelectBox
              fullWidth
              items={[
                { label: "Approved", value: "approved" },
                { label: "Rejected", value: "rejected" },
              ]}
              label="Status"
              name="status"
              required
              error={formErrors?.status}
              value={formData?.status}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>

        {formData?.status === "rejected" && (
          <Grid item sm={8} xs={12}>
            <InputField
              label="Rejection Reason"
              name="reason"
              required
              error={formErrors?.reason}
              value={formData?.reason}
              onChange={handleInputChange}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">Awaiting For Publish Details</Typography>
          {formInputs()}
          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpAwaitingForPublishDetails;
