import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { grey } from "@mui/material/colors";

import ChartPanel from "./chart-panel/chart-panel";
import ApiManager from "../../../services/api-manager";
import { handleLoader } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../../utils/utils";
import { BarChart } from "@mui/x-charts/BarChart";
import { Doughnut, Pie } from "react-chartjs-2";
import { PieChart } from "@mui/x-charts/PieChart";
import _ from "lodash";

const Overview = () => {
  const { id } = useParams();
  const [records, setRecords] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent?.currencySymbol;

  // Manage Data for Graph
  // const totalTicketValuesForGraph = _?.map(records?.ticket_graph, "total")?.map(
  //   (value) => Number(value),
  // );
  // const revenueValuesForGraph = _?.map(records?.revenue_graph, "revenue")?.map(
  //   (value) => Number(value),
  // );
  // const personValuesForGraph = _?.map(records?.in_person_graph, "total")?.map(
  //   (value) => Number(value),
  // );
  // const attendeeValuesForGraph = _?.map(records?.attendee_graph, "total")?.map(
  //   (value) => Number(value),
  // );
  // const merchandiseForGraph = _?.map(records?.merchandiseGraph, "total")?.map(
  //   (value) => Number(value),
  // );
  // const onlineTopupsValuesForGraph = _?.map(
  //   records?.online_topup_graph,
  //   "total",
  // )?.map((value) => Number(value));

  // const dateForGraph = _?.map(records?.ticket_graph, "date");

  // manage data for vendors pie

  const vendorsName = _?.map(records?.top_vendors, "name");
  const vendorsTotal = _?.map(records?.top_vendors, "total")?.map((value) =>
    Number(value),
  );

  const topMerchandisesDate = _?.map(records?.topMerchandises, "name");
  const topMerchandisesTotal = _?.map(records?.topMerchandises, "total")?.map(
    (value) => Number(value),
  );

  const voucherRedeemQuantity = _?.map(
    records?.vouchers_availed_items,
    "product",
  );
  const voucherRedeemProduct = _?.map(
    records?.vouchers_availed_items,
    "quantity",
  )?.map((value) => Number(value));

  // const vendorDataForPieChart = records?.top_vendors?.map((vendor, i) => ({
  //   id: i,
  //   value: parseFloat(vendor?.total),
  //   label: vendor?.name,
  // }));

  // const topMerchandisesDataForPieChart = records?.topMerchandises?.map(
  //   (merchandise, i) => ({
  //     id: i,
  //     value: parseFloat(merchandise?.total),
  //     label: merchandise?.name,
  //   })
  // );

  // const redeemedVoucherItemsDataForGraph = records?.vouchers_availed_items.map(
  //   (item, i) => ({
  //     id: i,
  //     value: parseFloat(item?.quantity),
  //     label: item?.product + " " + item?.voucher,
  //   })
  // );

  async function init() {
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager("get", `${_role}/overview/${id}`);
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: overview.js:14 ~ init ~ error:", error);
    } finally {
      dispatch(handleLoader(false));
    }
  }

  useEffect(() => {
    init();
  }, [dispatch, id]);

  const renderVendorsList = () => (
    <Paper
      elevation={2}
      sx={{
        mt: 3,
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Highest Grossing Vendors
      </Typography>
      <Box mt={1}>
        {records?.top_vendors?.length ? (
          <Fragment>
            <Stack
              direction="row"
              justifyContent="center"
              width="100%"
              maxHeight="500px"
            >
              <Doughnut
                data={{
                  labels: vendorsName,
                  datasets: [
                    {
                      data: vendorsTotal,
                      label: "total",
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                  height: 300,
                }}
              />
            </Stack>
            <Divider />
          </Fragment>
        ) : (
          <Typography
            variant="subtitle1"
            color="#ccc"
            style={{ textAlign: "center", fontSize: "12px" }}
          >
            No records
          </Typography>
        )}
      </Box>
      <br />
      <Button
        fullWidth
        onClick={() =>
          navigate(`/event/${id}/management/lead-capture/vendor-report`)
        }
      >
        <Typography variant="subtitle1" color="primary">
          View Complete Report
        </Typography>
      </Button>
    </Paper>
  );
  const renderMerchandiseList = () => (
    <Paper
      elevation={2}
      sx={{
        mt: 3,
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Highest Grossing Merchandise
      </Typography>
      <Box mt={1}>
        {records?.topMerchandises?.length ? (
          <Fragment>
            <Stack
              direction="row"
              justifyContent="center"
              width="100%"
              maxHeight="500px"
            >
              <Doughnut
                data={{
                  labels: topMerchandisesDate,
                  datasets: [
                    {
                      data: topMerchandisesTotal,
                      label: "total",
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                }}
              />
            </Stack>
            <Divider />
          </Fragment>
        ) : (
          <Typography
            variant="subtitle1"
            color="#ccc"
            style={{ textAlign: "center", fontSize: "12px" }}
          >
            No records
          </Typography>
        )}
      </Box>
      <br />
      <Button
        fullWidth
        onClick={() =>
          navigate(`/event/${id}/management/lead-capture/merchandise-report`)
        }
      >
        <Typography variant="subtitle1" color="primary">
          View Complete Report
        </Typography>
      </Button>
    </Paper>
  );
  const renderRedeemVoucherItemsList = () => (
    <Paper
      elevation={2}
      sx={{
        mt: 3,
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Redeemed Voucher Items
      </Typography>
      <Box mt={1}>
        {records?.vouchers_availed_items?.length ? (
          <>
            <Stack
              direction="row"
              justifyContent="center"
              width="100%"
              maxHeight="500px"
            >
              <Doughnut
                data={{
                  labels: voucherRedeemQuantity,
                  datasets: [
                    {
                      data: voucherRedeemProduct,
                      label: "Quantity",
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                }}
              />
            </Stack>
            <Divider />
          </>
        ) : (
          <Typography
            variant="subtitle1"
            color="#ccc"
            style={{ textAlign: "center", fontSize: "12px" }}
          >
            No records
          </Typography>
        )}
      </Box>
    </Paper>
  );
  const renderEventDetailsList = () => (
    <Grid item xs={12}>
      <Paper
        elevation={2}
        sx={{
          width: "100%",
          minHeight: "50vh",
          mt: 3,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={"semiBold"}
          sx={{
            p: 1,
          }}
        >
          Event Detail
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={4} border={1} p={1} borderColor={grey[200]}>
            <Typography variant="subtitle1">Event Detail</Typography>
          </Grid>
          <Grid item xs={8} border={1} p={1} borderColor={grey[200]}>
            <Typography variant="subtitle1">Event Detail</Typography>
          </Grid>
        </Grid>
        <PrintDetailFromObject
          title="Access Control"
          object={records?.access_control}
          symbol={_symbol}
        />
        <PrintDetailFromObject
          title="Cashless Sales"
          object={records?.cashless_sales}
          symbol={_symbol}
        />
        <PrintDetailFromObject
          title="Cashless Top Ups"
          object={records?.cashless_topups}
          symbol={_symbol}
        />
      </Paper>
    </Grid>
  );

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <Typography variant="h5">
            Overview&nbsp;:&nbsp;
            {loggedEvent?.name ? (
              records?.event_details?.portal_url ? (
                <a
                  href={records?.event_details?.portal_url}
                  target="blank"
                  style={{ color: "#000", cursor: "pointer" }}
                >
                  {loggedEvent?.name}
                </a>
              ) : (
                loggedEvent?.name
              )
            ) : (
              "...loading"
            )}
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography variant="subtitle1">
            Note: <br />
            Sale data maybe delayed or inaccurate due to unsynchronized device
          </Typography>
        </Grid>
        <Grid
          item
          sm={2}
          xs={12}
          display={"flex"}
          justifyContent="flex-end"
          alignItems={"center"}
        >
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => init()}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} lg={12}>
          <Grid container>
            <Grid item xs={12}>
              <Paper
                elevation={2}
                sx={{
                  mt: 3,
                  padding: "0 10px",
                }}
              >
                <ChartPanel symbol={_symbol} data={records} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* render highest grossing vendors list */}
        <Grid item xs={12} sm={12} lg={4}>
          {renderVendorsList()}
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          {renderMerchandiseList()}
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          {renderRedeemVoucherItemsList()}
        </Grid>

        {/* render event details list */}
        {renderEventDetailsList()}
      </Grid>
    </Box>
  );
};

export default Overview;

const PrintDetailFromObject = ({ title = "", object = {}, symbol = "" }) => {
  return (
    <>
      {Object.keys(object).length > 0 && (
        <Grid container>
          <Grid item xs={4} border={1} borderColor={grey[200]}>
            <Typography variant="subtitle1" p={0.9}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={4} border={1} borderColor={grey[200]}>
            {Object.keys(object).map((key, i) => {
              return (
                <Typography
                  variant="subtitle1"
                  sx={i > 0 ? { borderTop: 1, borderColor: grey[200] } : {}}
                  p={0.9}
                  key={Utils.generateId()}
                >
                  {Utils.removeUnderscore(Utils.capitalize(key || ""))}
                </Typography>
              );
            })}
          </Grid>
          <Grid item xs={4} border={1} borderColor={grey[200]}>
            {Object.keys(object).map((key, i) => {
              const isPresent = [
                "gross_sales",
                "gross_refunded_amount",
                "gross_topups",
                "onsite_topups",
                "online_topups",
              ].includes(key);
              return (
                <Typography
                  variant="subtitle1"
                  p={0.9}
                  sx={i > 0 ? { borderTop: 1, borderColor: grey[200] } : {}}
                  key={Utils.generateId()}
                >
                  {`${
                    (isPresent ? symbol : "") +
                      Utils.formatWithSuffix(object[key]) || 0
                  } ${Utils.removeUnderscore(Utils.capitalize(key || ""))}(s)`}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};
