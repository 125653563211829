import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, Typography, Stack, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

import { InputField } from "../../../../component"; // Remove unused imports
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

const CreateTopsMethods = () => {
  const params = useParams();
  const { id } = params;
  const topupMethodId = params?.topupMethodId;
  usePageTitle(topupMethodId ? "Update User" : "Create User");
  const [formData, setFormData] = useState({
    open_id: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _path = -1;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    async function init() {
      if (topupMethodId) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/topup-methods/${topupMethodId}`,
          );
          setFormData(data?.response?.detail);
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [topupMethodId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let _url = topupMethodId
        ? `organizer/topup-methods/${topupMethodId}`
        : `organizer/topup-methods`;
      await ApiManager("post", _url, {
        ...formData,
        event_id: id,
        ...(topupMethodId && { _method: "patch" }),
      });
      navigate(_path);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const renderFormInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={6} xs={12}>
          <Box>
            <InputField
              label="Title"
              name="title"
              required
              error={formErrors?.title}
              value={formData?.title}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box>
            <InputField
              label="Order num"
              name="order_num"
              required
              type="number"
              error={formErrors?.order_num}
              value={formData?.order_num}
              onChange={handleInputChange}
              inputProps={{
                min: 1,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {topupMethodId ? "Update" : "Create"} Top Up Methods
          </Typography>
          {renderFormInputs()}

          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateTopsMethods;
