import React from "react";
import { Box } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import PaymentIcon from "@mui/icons-material/Payment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CategoryIcon from "@mui/icons-material/Category";
import SettingsIcon from "@mui/icons-material/Settings";

import { AdminDrawer, AdminHeader } from "../../component";
import ApiManager from "../../services/api-manager";
import { handleEvent } from "../../store/reducer";

const SecondaryLayout = ({ children, layout }) => {
  const { id } = useParams();
  const [logo, setLogo] = React.useState(null);
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      try {
        let { data } = await ApiManager("get", `${_role}/events/${id}`);
        setLogo(data?.image_url);
        dispatch(
          handleEvent({
            name: data?.name,
            currencySymbol: data?.currency?.currency_symbol,
            vouchers_allowed: data?.vouchers_allowed,
          }),
        );
      } catch (error) {
        console.log("🚀 ~ file: secondary-layout.js:24 ~ init ~ error:", error);
      }
    }
    init();
    return () => {
      dispatch(handleEvent({}));
    };
  }, []);

  return (
    <Box>
      <AdminHeader logo={logo} />
      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
        }}
      >
        <AdminDrawer routes={routes(id)} />

        <Box
          component={"main"}
          sx={{
            width: { md: "calc(100% - 260px)", xs: "100%" },
            padding: "16px",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const routes = (id) => {
  return [
    {
      label: "Overview",
      url: `/event/${id}/management/overview`,
      routeName: "/event/management/overview",
      icon: <PreviewIcon />,
      children: [],
    },
    {
      label: "Attendee",
      url: `/event/${id}/management/attendee`,
      routeName: "/event/management/attendee",
      icon: <GroupsIcon />,
      children: [],
    },
    {
      label: "Merchandise",
      url: `/event/${id}/management/merchandise`,
      routeName: "/event/management/merchandise",
      icon: <CategoryIcon />,
      children: [],
    },
    {
      label: "User Management",
      url: `/event/${id}/management/user-management`,
      path: `/event/${id}/management/user-management`,
      icon: <ManageAccountsIcon />,
      children: [],
    },
    {
      label: "Test Cards",
      url: `/event/${id}/test-card`,
      path: `/event/${id}/test-card`,
      icon: <PaymentIcon />,
      children: [],
    },
    {
      label: "Top up Methods",
      url: `/event/${id}/top-up-methods`,
      path: `/event/${id}/top-up-methods`,
      icon: <AttachMoneyIcon />,
      children: [],
    },
    {
      label: "Access Control",
      url: ``,
      path: ``,
      icon: <LockIcon />,
      children: [
        {
          label: "Access Control Point",
          url: `/event/${id}/management/access-control/access-control-points`,
          routeName: `/event/${id}/management/access-control/access-control-points`,
        },
        {
          label: "Discount Vouchers",
          url: `/event/${id}/management/access-control/discount-vouchers`,
          routeName: `/event/${id}/management/access-control/discount-vouchers`,
        },
        {
          label: "Ticket Types",
          url: `/event/${id}/management/access-control/ticket-types`,
          routeName: `/event/${id}/management/access-control/ticket-types`,
        },
        {
          label: "Tickets",
          url: `/event/${id}/management/access-control/tickets`,
          routeName: `/event/${id}/management/access-control/tickets`,
        },
      ],
    },
    {
      label: "Reports",
      url: ``,
      routeName: "",
      icon: <InfoIcon />,
      children: [
        {
          label: "Ticket Report",
          url: `/event/${id}/management/lead-capture/ticket-report`,
          routeName: `/event/${id}/management/lead-capture/ticket-report`,
        },
        {
          label: "Attendee Report",
          url: `/event/${id}/management/lead-capture/attendee-report`,
          routeName: `/event/${id}/management/lead-capture/attendee-report`,
        },
        {
          label: "Revenue Report",
          url: `/event/${id}/management/lead-capture/revenue-report`,
          routeName: `/event/${id}/management/lead-capture/revenue-report`,
        },
        {
          label: "Vouchers Report",
          url: `/event/${id}/management/lead-capture/vouchers-report`,
          routeName: `/event/${id}/management/lead-capture/vouchers-report`,
        },
        {
          label: "In Person TopUp",
          url: `/event/${id}/management/lead-capture/in-person-topup-report`,
          routeName: `/event/${id}/management/lead-capture/in-person-topup-report`,
        },
        {
          label: "Staff Credit Report",
          url: `/event/${id}/management/lead-capture/staff-credits-report`,
          routeName: `/event/${id}/management/lead-capture/staff-credits-report`,
        },
        {
          label: "Online Person TopUp",
          url: `/event/${id}/management/lead-capture/online-topup-report`,
          routeName: `/event/${id}/management/lead-capture/online-topup-report`,
        },
        {
          label: "Refunds report",
          url: `/event/${id}/management/lead-capture/refunds-reports`,
          routeName: `/event/${id}/management/lead-capture/refunds-reports`,
        },
        {
          label: "Vendor Report",
          url: `/event/${id}/management/lead-capture/vendor-report`,
          routeName: `/event/${id}/management/lead-capture/vendor-report`,
        },
        {
          label: "Merchandise Report",
          url: `/event/${id}/management/lead-capture/merchandise-report`,
          routeName: `/event/${id}/management/lead-capture/merchandise-report`,
        },
      ],
    },
    {
      label: "Cashless",
      url: ``,
      routeName: "",
      icon: <MonetizationOnIcon />,
      children: [
        {
          label: "Vendors",
          url: `/event/${id}/management/cashless/vendors`,
          routeName: `/event/${id}/management/cashless/vendors`,
        },
        {
          label: "Product Categories",
          url: `/event/${id}/management/cashless/category`,
          routeName: `/event/${id}/management/cashless/category`,
        },
      ],
    },
    {
      label: "Settings",
      url: `/event/${id}/settings`,
      path: `/event/${id}/settings`,
      icon: <SettingsIcon />,
      children: [],
    },
  ];
};

export default SecondaryLayout;
