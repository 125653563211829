import React from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Router from "./router/router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch } from "react-redux";

import ApiManager from "./services/api-manager";
import { setToast, setUser } from "./store/reducer";
import { SplashScreen } from "./component";
import InternetStatus from "./component/internet-status/internet-status";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "Work Sans"].join(","),
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
    ExtraBold: 900,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#0081C5",
      gradient: "linear-gradient(102.28deg,#0081C5 0%, #006FAE 104.87%);",
    },
    secondary: {
      main: "#33BCA1",
      light: "#fff",
      dimLight: "#F3F3F3",
    },
  },
});

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      if (isLoading) {
        let token = localStorage.getItem(process.env.REACT_APP_TOKEN);
        if (token) {
          try {
            let { data } = await ApiManager("get", "me");
            dispatch(setUser(data));
          } catch (error) {
            console.log("🚀 ~ file: App.js:44 ~ useEffect ~ error:", error);
          } finally {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    }
    init();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <InternetStatus />
        <Router />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
