import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpEventCategories = () => {
  usePageTitle("Event Categories");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager("get", "superAdmin/event-categories");
      setRecords(data?.response?.detail);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleStatus = async (e, item) => {
    setRecords((prev) =>
      prev.map((obj) => {
        if (obj.category_id === item.category_id) {
          return { ...obj, active: e.target.checked };
        }
        return obj;
      }),
    );
    let _fd = {
      _method: "patch",
      status: e.target.checked ? "active" : "not-active",
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager(
        "post",
        `superAdmin/event-categories/${item?.category_id}`,
        _fd,
      );
      showMessage("success", "Record status updated successfully.");
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    "Title",
    "Parent",
    "Icon",
    "Banner Image",
    "Status",
    "Actions",
  ];

  return (
    <Box>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        Event Categories
      </Typography>
      <Stack direction="row" my={2}>
        <Button
          component={Link}
          to="/admin/event-categories/create-event-category"
          startIcon={<AddIcon />}
        >
          Create Event Category
        </Button>
      </Stack>
      <TableWrapper
        spanTd={thLabels.length}
        isLoading={isLoading}
        isContent={records?.length || false}
        thContent={
          <>
            {thLabels.map((v) => (
              <TableCell key={v}>{v}</TableCell>
            ))}
          </>
        }
      >
        {records?.length &&
          records?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.title}</StyledTableCell>
              <StyledTableCell>
                {item?.parent_category?.title ?? "-"}
              </StyledTableCell>
              <StyledTableCell>
                <i class={`fa ${item?.icon_class}`} aria-hidden="true"></i>
              </StyledTableCell>
              {/* <StyledTableCell>
                <img
                  src={item?.icon_active_url}
                  width="45px"
                  height="45px"
                  alt="active-icon.png"
                />
              </StyledTableCell> */}
              <StyledTableCell>
                <img
                  src={item?.banner_image_url}
                  width="45px"
                  height="45px"
                  alt="banner.png"
                />
              </StyledTableCell>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item?.active}
                      onChange={(e) => handleStatus(e, item)}
                    />
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/admin/event-categories/create-event-category/${item?.category_id}`,
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </TableWrapper>
    </Box>
  );
};

export default SpEventCategories;
