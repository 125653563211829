import React, { useEffect, useState } from "react";
import {
  AppBar,
  Badge,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment/moment";

import { Logo } from "../../assets";
import { setToast, toggleMenu } from "../../store/reducer";
import ApiManager from "../../services/api-manager";
import Utils from "../../utils/utils";

export default function AdminHeader({ logo: propsLogo, hideProfile = false }) {
  const _openMenu = useSelector((state) => state.storeReducer.openMenu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:900px)");
  const renderLogo = propsLogo ? propsLogo : Logo;
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.storeReducer);
  const [notificationsData, setNotificationsData] = useState(null);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(true);
  const _role = user?.role === "super_admin" ? "superAdmin" : user?.role;
  const [unRead, setUnRead] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getData();
  }, [navigate]);

  async function getData() {
    try {
      setIsNotificationsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/get-notifications?page=1&per_page=50`,
      );
      if (data?.data?.length > 0) {
        setUnRead(() => {
          let read = 0;
          data?.data.forEach((obj) => {
            if (!obj.read) {
              read++;
            }
          });
          return read;
        });
      }
      setNotificationsData(data);
    } catch (error) {
      console.log("🚀 ~ file: admin-header.js:33 ~ init ~ error:", error);
    } finally {
      setIsNotificationsLoading(false);
    }
  }

  const handleDelete = async (item) => {
    try {
      setIsNotificationsLoading(true);
      await ApiManager("post", `${_role}/delete-notification`);
      getData();
      dispatch(
        setToast({
          type: "success",
          message: "Notification deleted successfully.",
        }),
      );
    } catch (error) {
      console.log(
        "🚀 ~ file: admin-header.js:56 ~ handleNotificationClick ~ error:",
        error,
      );
    }
  };

  const handleNotificationClick = async (item) => {
    switch (item?.notif?.type) {
      case "currency":
        navigate(`/admin/currency/update/${item?.notif?.currency_id}`);
        break;
      case "event":
        navigate(`/event/${item?.notif?.event_id}/management/overview`);
        break;
      case "vendor":
        navigate(
          `/event/${item?.notif?.event_id}/management/cashless/vendors/manage/${item?.notif?.vendor_id}/product`,
        );
        break;
      case "organizer":
        navigate(`/admin/organizer/update/${item?.notif?.user_id}`);
        break;
      default:
        break;
    }
    if (!item?.read) {
      try {
        await ApiManager(
          "post",
          `${_role}/update-notification/${item?.notification_id}`,
        );
        let updateData = notificationsData?.data.map((obj) => {
          if (item?.notification_id === obj?.notification_id) {
            return { ...obj, read: true };
          }
          return obj;
        });
        setUnRead(unRead - 1);
        setNotificationsData((prev) => ({ ...prev, data: updateData }));
      } catch (error) {
        console.log(
          "🚀 ~ file: admin-header.js:56 ~ handleNotificationClick ~ error:",
          error,
        );
      }
    }
  };

  const notificationHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: "1px solid #ccc", pb: 1, px: 2 }}
        spacing={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>Notifications</Typography>
        <Stack direction="row" spacing={1}>
          <Tooltip title="Delete all notifications" placement="top">
            <IconButton
              onClick={handleDelete}
              disabled={isNotificationsLoading}
              size="small"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh notifications" placement="top">
            <IconButton
              onClick={getData}
              disabled={isNotificationsLoading}
              size="small"
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    );
  };

  const notificationBtn = () => {
    return (
      <Box sx={{ position: "relative" }}>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpen}
        >
          <Badge
            badgeContent={unRead}
            sx={{
              ".MuiBadge-badge": { bgcolor: "#fff", color: "primary.main" },
            }}
          >
            <NotificationsIcon
              sx={{
                color: "#fff",
                fontSize: "2.4rem",
              }}
            />
          </Badge>
        </IconButton>
        {open && (
          <ClickAwayListener onClickAway={handleClose} key={Math.random()}>
            <Box component={Paper} sx={styles.absBox(matches)} elevation={6}>
              {notificationHeader()}
              {isNotificationsLoading ? (
                <Box sx={{ textAlign: "center", py: 3 }}>
                  <CircularProgress sx={{ fontSize: 18 }} />
                </Box>
              ) : notificationsData?.data.length ? (
                notificationsData?.data.map((item, i) => {
                  return (
                    <>
                      {/* <Stack direction="row" justifyContent="flex-end">
                    <Tooltip title="Delete Notification" placement="top">
                      <IconButton onClick={() => handleDelete(item)} size="small">
                        <CloseIcon sx={{ fontSize: 10 }} />
                      </IconButton>
                    </Tooltip>
                  </Stack> */}
                      <MenuItem
                        sx={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => handleNotificationClick(item)}
                        key={i}
                        selected={!item?.read}
                      >
                        <NotificationItem data={item} />
                      </MenuItem>
                    </>
                  );
                })
              ) : (
                <Box sx={{ textAlign: "center", py: 3 }}>
                  <Typography sx={{ color: "text.secondary" }}>
                    No Notification
                  </Typography>
                </Box>
              )}
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    );
  };

  return (
    <AppBar
      position="sticky"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Link to="/">
            <Box
              sx={{
                ml: 1,
                p: 1,
              }}
            >
              <img
                src={renderLogo}
                alt="Logo"
                width="100px"
                height="56px"
                style={{
                  filter: "drop-shadow(0px 10px 10px rgba(0,0,0,0.75))",
                }}
              />
            </Box>
          </Link>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* {user?.role === "super_admin" ? notificationBtn() : null} */}
            <Box>
              <Link to="/profile">
                <PersonIcon
                  sx={{
                    color: "white",
                    fontSize: "2.4rem",
                  }}
                />
              </Link>
            </Box>
            {notificationBtn()}
            {!matches && (
              <IconButton
                onClick={() => dispatch(toggleMenu(!_openMenu))}
                disabled={matches}
              >
                {_openMenu ? (
                  <CloseIcon
                    sx={{
                      color: "white",
                      fontSize: "2.4rem",
                    }}
                  />
                ) : (
                  <ViewHeadlineIcon
                    sx={{
                      color: "white",
                      fontSize: "2.4rem",
                    }}
                  />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const NotificationItem = ({ data }) => {
  const { text = "", created_at = "", type = "" } = data.notif;
  return (
    <>
      <Box sx={styles.container}>
        <Typography sx={styles.title(data?.read)}>
          {Utils.capitalize(type)}
        </Typography>
        <Typography sx={styles.text}>{text}</Typography>
        <Typography sx={styles.date}>
          {moment(created_at).format(process.env.REACT_APP_DATETIME_FORMAT)}
        </Typography>
      </Box>
    </>
  );
};

const styles = {
  absBox: (matches) => {
    return {
      position: "absolute",
      right: matches ? 0 : -45,
      top: 75,
      width: "100%",
      minWidth: "350px",
      maxHeight: "450px",
      overflow: "auto",
      zIndex: 99,
      py: 1,
    };
  },
  container: {
    pb: 1,
    width: "100%",
    // borderBottom: "1px solid #ccc"
  },
  title: (read = false) => {
    return {
      fontSize: 14,
      mb: "3px",
      fontWeight: read ? "regular" : "bold",
    };
  },
  text: {
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  date: {
    fontSize: 10,
    textAlign: "end",
    mt: 1,
  },
};
