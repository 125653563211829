import React from "react";
import { useDispatch } from "react-redux";
import { setToast } from "../../store/reducer";

const InternetStatus = () => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      dispatch(
        setToast({
          type: "success",
          message: "Internet Connection is Recovered",
        }),
      );
    };

    const handleOffline = () => {
      setIsOnline(false);
      dispatch(
        setToast({
          type: "error",
          message: "Internet Connection is Not Available",
        }),
      );
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
};

export default InternetStatus;
