import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";

import _ from "lodash";
import ChartPanel from "../../events/overview/chart-panel/chart-panel";
import ApiManager from "../../../services/api-manager";
import { handleLoader } from "../../../store/reducer";
import Utils from "../../../utils/utils";
import { SelectBox } from "../../../component";

const PtEventStatistics = () => {
  const { id } = useParams();
  const [records, setRecords] = useState(null);
  const [organizer, setOrganizer] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : user?.role;
  const _symbol = loggedEvent?.currencySymbol;

  const vendorsName = _?.map(records?.top_vendors, "name");
  const vendorsTotal = _?.map(records?.top_vendors, "total")?.map((value) =>
    Number(value),
  );

  const topMerchandisesDate = _?.map(records?.topMerchandises, "name");
  const topMerchandisesTotal = _?.map(records?.topMerchandises, "total")?.map(
    (value) => Number(value),
  );

  const voucherRedeemQuantity = _?.map(
    records?.vouchers_availed_items,
    "product",
  );
  const voucherRedeemProduct = _?.map(
    records?.vouchers_availed_items,
    "quantity",
  )?.map((value) => Number(value));

  useEffect(() => {
    async function getOverview() {
      try {
        dispatch(handleLoader(true));
        let { data } = await ApiManager(
          "get",
          `${_role}/overview/${selectedData?.event_id}`,
        );
        // let { data } = await ApiManager("get", `${_role}/overview/4dbb2f37-ecf6-44c6-a348-0490ac9d30b6`);
        setRecords(data);
      } catch (error) {
        console.log("🚀 ~ file: overview.js:14 ~ init ~ error:", error);
      } finally {
        dispatch(handleLoader(false));
      }
    }
    if (selectedData?.event_id) {
      getOverview();
    }
  }, [selectedData?.event_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const getOrganizer = async () => {
      try {
        dispatch(handleLoader(true));
        let { data } = await ApiManager(
          "get",
          `partner/get-organizers?per_page=999999`,
        );
        let dataForSelectBox = data?.users?.data?.map((e) => ({
          label: e?.first_name + " " + e?.last_name,
          value: e?.user_id,
        }));
        setOrganizer(dataForSelectBox);
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      } finally {
        dispatch(handleLoader(false));
      }
    };
    getOrganizer();
  }, []);

  useEffect(() => {
    const getOrganizerEvents = async () => {
      try {
        setEvents([]);
        setLoadingEvents(true);
        let { data } = await ApiManager(
          "get",
          `partner/events?event_organizer=${selectedData?.organizer_id}&per_page=999999`,
        );
        let dataForSelectBox = data?.events?.data?.map((e) => ({
          label: e?.name,
          value: e?.event_id,
        }));
        setEvents(dataForSelectBox);
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      } finally {
        setLoadingEvents(false);
      }
    };

    if (selectedData?.organizer_id) {
      getOrganizerEvents();
    }
  }, [selectedData?.organizer_id]);

  const renderVendorsList = () => (
    <Paper
      elevation={2}
      sx={{
        mt: 3,
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Highest Grossing Vendors
      </Typography>
      <Box mt={1}>
        {records?.top_vendors?.length ? (
          <Fragment>
            <Stack
              direction="row"
              justifyContent="center"
              width="100%"
              maxHeight="500px"
            >
              <Doughnut
                data={{
                  labels: vendorsName,
                  datasets: [
                    {
                      data: vendorsTotal,
                      label: "total",
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                  height: 300,
                }}
              />
            </Stack>
            <Divider />
          </Fragment>
        ) : (
          <Typography
            variant="subtitle1"
            color="#ccc"
            style={{ textAlign: "center", fontSize: "12px" }}
          >
            No records
          </Typography>
        )}
      </Box>
      <br />
      <Button
        fullWidth
        onClick={() =>
          navigate(`/event/${id}/management/lead-capture/vendor-report`)
        }
      >
        <Typography variant="subtitle1" color="primary">
          View Complete Report
        </Typography>
      </Button>
    </Paper>
  );
  const renderMerchandiseList = () => (
    <Paper
      elevation={2}
      sx={{
        mt: 3,
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Highest Grossing Merchandise
      </Typography>
      <Box mt={1}>
        {records?.topMerchandises?.length ? (
          <Fragment>
            <Stack
              direction="row"
              justifyContent="center"
              width="100%"
              maxHeight="500px"
            >
              <Doughnut
                data={{
                  labels: topMerchandisesDate,
                  datasets: [
                    {
                      data: topMerchandisesTotal,
                      label: "total",
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                }}
              />
            </Stack>
            <Divider />
          </Fragment>
        ) : (
          <Typography
            variant="subtitle1"
            color="#ccc"
            style={{ textAlign: "center", fontSize: "12px" }}
          >
            No records
          </Typography>
        )}
      </Box>
      <br />
      <Button
        fullWidth
        onClick={() =>
          navigate(`/event/${id}/management/lead-capture/merchandise-report`)
        }
      >
        <Typography variant="subtitle1" color="primary">
          View Complete Report
        </Typography>
      </Button>
    </Paper>
  );
  const renderRedeemVoucherItemsList = () => (
    <Paper
      elevation={2}
      sx={{
        mt: 3,
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Redeemed Voucher Items
      </Typography>
      <Box mt={1}>
        {records?.vouchers_availed_items?.length ? (
          <>
            <Stack
              direction="row"
              justifyContent="center"
              width="100%"
              maxHeight="500px"
            >
              <Doughnut
                data={{
                  labels: voucherRedeemQuantity,
                  datasets: [
                    {
                      data: voucherRedeemProduct,
                      label: "Quantity",
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "right",
                    },
                  },
                }}
              />
            </Stack>
            <Divider />
          </>
        ) : (
          <Typography
            variant="subtitle1"
            color="#ccc"
            style={{ textAlign: "center", fontSize: "12px" }}
          >
            No records
          </Typography>
        )}
      </Box>
    </Paper>
  );
  const renderEventDetailsList = () => (
    <Grid item xs={12}>
      <Paper
        elevation={2}
        sx={{
          width: "100%",
          minHeight: "50vh",
          mt: 3,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={"semiBold"}
          sx={{
            p: 1,
          }}
        >
          Event Detail
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={4} border={1} p={1} borderColor={grey[200]}>
            <Typography variant="subtitle1">Event Detail</Typography>
          </Grid>
          <Grid item xs={8} border={1} p={1} borderColor={grey[200]}>
            <Typography variant="subtitle1">Event Detail</Typography>
          </Grid>
        </Grid>
        <PrintDetailFromObject
          title="Access Control"
          object={records?.access_control}
          symbol={_symbol}
        />
        <PrintDetailFromObject
          title="Cashless Sales"
          object={records?.cashless_sales}
          symbol={_symbol}
        />
        <PrintDetailFromObject
          title="Cashless Top Ups"
          object={records?.cashless_topups}
          symbol={_symbol}
        />
      </Paper>
    </Grid>
  );

  return (
    <Box>
      {/* <Grid container spacing={2}>
                <Grid item md={4} sm={12} >
                    <Typography variant="h5">
                        Event Statistics:
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <SelectBox
                        label="Organizers"
                        name="organizer_id"
                        items={organizer}
                        value={selectedData?.organizer_id}
                        onChange={handleInputChange}
                        isContent={organizer?.length}
                        sx={{ width: 1 }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <SelectBox
                        label="Organizer Event"
                        items={events}
                        name="event_id"
                        isLoading={loadingEvents}
                        value={selectedData?.event_id}
                        onChange={handleInputChange}
                        isContent={events?.length}
                    />
                </Grid>
            </Grid> */}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        gap={1}
        justifyContent="space-between"
      >
        <Typography variant="h5">Event Statistics:</Typography>
        <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
          <SelectBox
            label="Organizers"
            name="organizer_id"
            items={organizer}
            value={selectedData?.organizer_id}
            onChange={handleInputChange}
            isContent={organizer?.length}
          />
          <SelectBox
            label="Organizer Event"
            items={events}
            name="event_id"
            isLoading={loadingEvents}
            value={selectedData?.event_id}
            onChange={handleInputChange}
            isContent={events?.length}
          />
        </Stack>
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} lg={12}>
          <Grid container>
            <Grid item xs={12}>
              <Paper
                elevation={2}
                sx={{
                  mt: 3,
                  padding: "0 10px",
                }}
              >
                <ChartPanel symbol={_symbol} data={records} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* render highest grossing vendors list */}
        <Grid item xs={12} sm={12} lg={4}>
          {renderVendorsList()}
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          {renderMerchandiseList()}
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          {renderRedeemVoucherItemsList()}
        </Grid>

        {/* render event details list */}
        {renderEventDetailsList()}
      </Grid>
    </Box>
  );
};

export default PtEventStatistics;

const PrintDetailFromObject = ({ title = "", object = {}, symbol = "" }) => {
  return (
    <>
      {Object.keys(object).length > 0 && (
        <Grid container>
          <Grid item xs={4} border={1} borderColor={grey[200]}>
            <Typography variant="subtitle1" p={0.9}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={4} border={1} borderColor={grey[200]}>
            {Object.keys(object).map((key, i) => {
              return (
                <Typography
                  variant="subtitle1"
                  sx={i > 0 ? { borderTop: 1, borderColor: grey[200] } : {}}
                  p={0.9}
                  key={Utils.generateId()}
                >
                  {Utils.removeUnderscore(Utils.capitalize(key || ""))}
                </Typography>
              );
            })}
          </Grid>
          <Grid item xs={4} border={1} borderColor={grey[200]}>
            {Object.keys(object).map((key, i) => {
              const isPresent = [
                "gross_sales",
                "gross_refunded_amount",
                "gross_topups",
                "onsite_topups",
                "online_topups",
              ].includes(key);
              return (
                <Typography
                  variant="subtitle1"
                  p={0.9}
                  sx={i > 0 ? { borderTop: 1, borderColor: grey[200] } : {}}
                  key={Utils.generateId()}
                >
                  {`${
                    (isPresent ? symbol : "") +
                      Utils.formatWithSuffix(object[key]) || 0
                  } ${Utils.removeUnderscore(Utils.capitalize(key || ""))}(s)`}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};
