import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FilePicker, InputField } from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../store/reducer";
import errorsSetter from "../../../helpers/error-setter";
import ApiManager from "../../../services/api-manager";

function SpAddPaymentMethods() {
  usePageTitle("Add Payment Methods");
  const [formData, setFormData] = useState({});
  console.log("🚀 ~ SpAddPaymentMethods ~ formData:", formData);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const { id } = useParams();

  useEffect(() => {
    async function init() {
      if (id) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/payment-methods/${id}`,
          );
          setFormData({ ...data?.response?.detail, image: null });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      let url = id
        ? `superAdmin/payment-methods/${id}`
        : "superAdmin/payment-methods";
      let { data } = await ApiManager(
        "post",
        url,
        { ...formData, ...(id && { _method: "patch" }) },
        {
          "Content-Type": "multipart/form-data",
        },
      );
      navigate(-1);
      showMessage("success", data?.response);
      console.log("🚀 ~ handleSubmit ~ data?.message:", data?.response);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  console.log(formData);

  return (
    <Box
      component={Paper}
      elevation={6}
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {id ? "Update" : "Add"} Payment Methods
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="form"
            autoComplete="off"
            autoCapitalize="off"
            onSubmit={handleSubmit}
          >
            <Box mb={3}>
              <FilePicker
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    image: file,
                    image_url: URL.createObjectURL(file),
                  }));
                }}
                labelTop="Image"
                error={formErrors?.image}
                imageUrl={formData?.image_url}
                helperText="Recommended file dimension[WxH] : 100x56"
                title="Pick a file"
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12 / 3} sm={4} xs={12} lg={3}>
                <InputField
                  fullWidth
                  label="Title"
                  name="title"
                  required
                  error={formErrors?.title}
                  value={formData?.title}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={12 / 3} sm={4} xs={12} lg={3}>
                <InputField
                  fullWidth
                  label="Type"
                  name="type"
                  required
                  type="number"
                  error={formErrors?.type}
                  value={formData?.type}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={12 / 3} sm={4} xs={12} lg={3}>
                <InputField
                  fullWidth
                  label="Fees Percentage"
                  name="fees_percentage"
                  required
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  error={formErrors?.fees_percentage}
                  value={formData?.fees_percentage}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={12 / 3} sm={4} xs={12} lg={3}>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={formData?.status}
                      onChange={(e) => setFormData({ `...formData, status: e.target.checked ? "active" : "not-active" })}
                    />
                  }
                /> */}
                <InputField
                  size="small"
                  fullWidth
                  onChange={handleInputChange}
                  name="fix_amount"
                  label="Fixed Amount"
                  type="number"
                  value={formData.fix_amount}
                  error={formErrors?.fix_amount}
                />
              </Grid>
              <Grid item md={12 / 3} sm={4} xs={12} lg={6}>
                <InputField
                  size="small"
                  fullWidth
                  onChange={handleInputChange}
                  name="countries"
                  label="Countries"
                  value={formData.countries}
                  error={formErrors?.countries}
                />
              </Grid>
            </Grid>
            <Box my={2} children={<Divider />} />

            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SpAddPaymentMethods;
