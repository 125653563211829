import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { InputField, FilePicker, InputDate } from "../../../../component";
import usePageTitle from "../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import moment from "moment";

function SpCreatePartner() {
  const id = useParams()?.id || null;
  usePageTitle(`${id ? "Update" : "Create"} Merchandise`);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (id) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/get-partner/${id}`,
          );
          console.log("🚀 ~ init ~ data:", data);
          setFormData({ ...data });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("🚀 ~ handleInputChange ~ value:", value, name);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let _url = id
        ? `superAdmin/update-partner/${id}`
        : "superAdmin/add-partner";
      let { data } = await ApiManager(
        "post",
        _url,
        { ...formData, status: formData?.status ? "active" : "not-active" },
        {
          "Content-Type": "multipart/form-data",
        },
      );
      showMessage("success", data?.response);
      navigate(-1);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box
      Box
      component={Paper}
      elevation={6}
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="SemiBold">
            {id ? "Update" : "Create"} Partner
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="form"
            autoComplete="off"
            autoCapitalize="off"
            onSubmit={handleSubmit}
          >
            <Stack direction="row" gap={6}>
              <Box mb={3}>
                <FilePicker
                  _id="picker1"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFormData((prev) => ({
                      ...prev,
                      organization_logo: file,
                      organization_logo_url: file && URL.createObjectURL(file),
                    }));
                  }}
                  labelTop="Organization Logo"
                  error={formErrors?.organization_logo}
                  imageUrl={formData?.organization_logo_url}
                  title="Pick a file"
                />
              </Box>
              <Box mb={3}>
                <FilePicker
                  _id="picker2"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFormData((prev) => ({
                      ...prev,
                      profile_image: file,
                      image_url: file && URL.createObjectURL(file),
                    }));
                  }}
                  labelTop="Profile Image"
                  error={formErrors?.profile_image}
                  imageUrl={formData?.image_url}
                  title="Pick a file"
                />
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="First Name"
                  name="first_name"
                  required
                  error={formErrors?.first_name}
                  value={formData?.first_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Last Name"
                  name="last_name"
                  required
                  error={formErrors?.last_name}
                  value={formData?.last_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Email"
                  name="email"
                  required
                  type="email"
                  error={formErrors?.email}
                  value={formData?.email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Phone"
                  name="phone_number"
                  required
                  type="tel"
                  error={formErrors?.phone_number}
                  value={formData?.phone_number}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputDate
                  label="Date of Birth"
                  error={formErrors?.dob}
                  value={moment(formData?.dob)}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, dob: val }))
                  }
                  required
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Organization Name"
                  name="organization_name"
                  required
                  error={formErrors?.organization_name}
                  value={formData?.organization_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Address"
                  name="address"
                  required
                  error={formErrors?.address}
                  value={formData?.address}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="City"
                  name="city"
                  required
                  error={formErrors?.city}
                  value={formData?.city}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="State"
                  name="state"
                  required
                  error={formErrors?.state}
                  value={formData?.state}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Country"
                  name="country"
                  required
                  error={formErrors?.country}
                  value={formData?.country}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Website Url"
                  name="website_url"
                  required
                  error={formErrors?.website_url}
                  value={formData?.website_url}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Facebook Url"
                  name="facebook_link"
                  required
                  error={formErrors?.facebook_link}
                  value={formData?.facebook_link}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Password"
                  name="password"
                  required={id ? false : true}
                  error={formErrors?.password}
                  value={formData?.password}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="X Handle"
                  name="x_handle"
                  required
                  error={formErrors?.x_handle}
                  value={formData?.x_handle}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Zip Code"
                  name="zip_code"
                  required
                  error={formErrors?.zip_code}
                  value={formData?.zip_code}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <InputField
                label="Organizer Bio"
                name="organizer_bio"
                multiline
                rows={4}
                fullWidth
                required
                error={formErrors?.organizer_bio}
                value={formData?.organizer_bio}
                onChange={handleInputChange}
              />
            </Box>
            {id && (
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData?.status}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          status: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Status"
                />
              </Box>
            )}

            <Box my={2} children={<Divider />} />

            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SpCreatePartner;
