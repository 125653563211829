import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { InputField, FilePicker, InputDate } from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import errorsSetter from "../../../helpers/error-setter";

function Profile() {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : user?.role;

  useEffect(() => {
    setFormData(user);
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("🚀 ~ handleInputChange ~ value:", value, name);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let { data } = await ApiManager(
        "post",
        `${_role}/update-organizer/${user?.user_id}`,
        { ...formData },
        {
          "Content-Type": "multipart/form-data",
        },
      );
      showMessage("success", data?.response);
      navigate(-1);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="SemiBold">
            Profile
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="form"
            autoComplete="off"
            autoCapitalize="off"
            onSubmit={handleSubmit}
          >
            <Stack direction="row" gap={6}>
              <Box mb={3}>
                <FilePicker
                  _id="picker1"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFormData((prev) => ({
                      ...prev,
                      organization_logo: file,
                      organization_logo_url: file && URL.createObjectURL(file),
                    }));
                  }}
                  labelTop="Organization Logo"
                  error={formErrors?.organization_logo}
                  imageUrl={formData?.organization_logo_url}
                  title="Pick a file"
                />
              </Box>
              <Box mb={3}>
                <FilePicker
                  _id="picker2"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFormData((prev) => ({
                      ...prev,
                      profile_image: file,
                      image_url: file && URL.createObjectURL(file),
                    }));
                  }}
                  labelTop="Profile Image"
                  error={formErrors?.profile_image}
                  imageUrl={formData?.image_url}
                  title="Pick a file"
                />
              </Box>
            </Stack>

            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="First Name"
                  name="first_name"
                  required
                  error={formErrors?.first_name}
                  value={formData?.first_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Last Name"
                  name="last_name"
                  required
                  error={formErrors?.last_name}
                  value={formData?.last_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Email"
                  name="email"
                  required
                  type="email"
                  error={formErrors?.email}
                  value={formData?.email}
                  onChange={handleInputChange}
                  disabled
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Phone"
                  name="phone_number"
                  required
                  type="tel"
                  error={formErrors?.phone_number}
                  value={formData?.phone_number}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Password"
                  name="password"
                  error={formErrors?.password}
                  value={formData?.password}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputDate
                  label="Date of Birth"
                  error={formErrors?.dob}
                  value={moment(formData?.dob)}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, dob: val }))
                  }
                  required
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Organization Name"
                  name="organization_name"
                  required
                  error={formErrors?.organization_name}
                  value={formData?.organization_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Address"
                  name="address"
                  required
                  error={formErrors?.address}
                  value={formData?.address}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="City"
                  name="city"
                  required
                  error={formErrors?.city}
                  value={formData?.city}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="State"
                  name="state"
                  required
                  error={formErrors?.state}
                  value={formData?.state}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Country"
                  name="country"
                  required
                  error={formErrors?.country}
                  value={formData?.country}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Website Url"
                  name="website_url"
                  required
                  error={formErrors?.website_url}
                  value={formData?.website_url}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Facebook Url"
                  name="facebook_link"
                  required
                  error={formErrors?.facebook_link}
                  value={formData?.facebook_link}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="X Handle"
                  name="x_handle"
                  required
                  error={formErrors?.x_handle}
                  value={formData?.x_handle}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Zip Code"
                  name="zip_code"
                  required
                  error={formErrors?.zip_code}
                  value={formData?.zip_code}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <InputField
                label="Organizer Bio"
                name="organizer_bio"
                multiline
                rows={4}
                fullWidth
                required
                error={formErrors?.organizer_bio}
                value={formData?.organizer_bio}
                onChange={handleInputChange}
              />
            </Box>
            <Box my={2} children={<Divider />} />

            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                }}
              >
                Update
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Profile;
