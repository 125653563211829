import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import AddIcon from "@mui/icons-material/Add";

import {
  TablePagination,
  TableWrapper,
  StyledTableRow,
  StyledTableCell,
} from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import ApiManager from "../../../services/api-manager";
import { handleLoader, setToast } from "../../../store/reducer";
import calculateTime from "../../../hooks/calculate-time-diff";

function SpOrganizerBusinessInfo() {
  usePageTitle("Business Info");
  let { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        // `superAdmin/payment-methods?page=${page}&per_page=${rowsPerPage}`
        `superAdmin/get-business-information/${id}`,
      );
      setRecords(data?.response?.detail);
      // setColumns(data?.columns);

      // let timeDiff = calculateTime(
      //     data?.merchandiseProducts?.data[1]?.created_at
      // );
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(records);
  }, []);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (e, item) => {
    let _fd = {
      _method: "patch",
      status: e.target.checked === true ? "active" : "not-active",
    };
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager(
        "post",
        `superAdmin/payment-methods/${item?.payment_method_id}`,
        _fd,
      );
      setRecords((prev) =>
        prev?.map((obj) => {
          if (obj.payment_method_id === item.payment_method_id) {
            return { ...obj, active: e.target.checked };
          }
          return obj;
        }),
      );
      console.log(item);
      showMessage("success", data?.response);
    } catch (error) {
      // console.log(error);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const renderRow = (item) => (
    <StyledTableRow key={item?.payment_method_id}>
      <StyledTableCell>{item?.payment_method_id}</StyledTableCell>
      <StyledTableCell>{item?.title}</StyledTableCell>
      <StyledTableCell>{item?.type}</StyledTableCell>
      <StyledTableCell>
        <img
          src={item?.image_url}
          width="45px"
          height="45px"
          alt="product.png"
        />
      </StyledTableCell>
      <StyledTableCell>{Number(item?.fees_percentage)}%</StyledTableCell>
      <StyledTableCell>
        <FormControlLabel
          control={
            <Switch
              checked={item?.active}
              onChange={(e) => handleStatus(e, item)}
            />
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Tooltip title="Edit" placement="top">
            <IconButton
              color="info"
              onClick={() =>
                navigate(
                  `/admin/update-payment-methods/${item?.payment_method_id}`,
                )
              }
            >
              <CreateRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );

  // const handleCsvUpload = async (event) => {
  //     if (!event.target.files.length) {
  //         return;
  //     }
  //     let ext = event.target.files[0].name.split(".");
  //     if (ext[1] !== "csv") {
  //         return dispatch(
  //             setToast({
  //                 type: "error",
  //                 message: "Please select only .csv extension file.",
  //             }),
  //         );
  //     }
  //     try {
  //         dispatch(handleLoader(true));
  //         let _fd = new FormData();
  //         _fd.append("file", event.target.files[0]);
  //         let { data } = await ApiManager("post", "superAdmin/mp/upload-csv", _fd, {
  //             "Content-Type": "multipart/form-data",
  //         });
  //         showMessage("success", data);
  //         if (page === 1) {
  //             getData();
  //         } else {
  //             setPage(1);
  //         }
  //     } catch (error) {
  //         showMessage("error", error?.response?.data?.error?.message);
  //     } finally {
  //         dispatch(handleLoader(false));
  //     }
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
            Business Info
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Bank Account Number</Typography>
                <Typography variant="h6">
                  {records?.bank_account_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Bank Branch</Typography>
                <Typography variant="h6">{records?.bank_branch}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Bank Name</Typography>
                <Typography variant="h6">{records?.bank_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business City</Typography>
                <Typography variant="h6">{records?.business_city}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business Country</Typography>
                <Typography variant="h6">
                  {records?.business_country}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business Information ID</Typography>
                <Typography variant="h6">
                  {records?.business_information_id}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business Name</Typography>
                <Typography variant="h6">{records?.business_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business Postal Code</Typography>
                <Typography variant="h6">
                  {records?.business_postal_code}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business State</Typography>
                <Typography variant="h6">{records?.business_state}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business Street Address</Typography>
                <Typography variant="h6">
                  {records?.business_street_address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Business Type</Typography>
                <Typography variant="h6">{records?.business_type}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Chamber of Commerce Number</Typography>
                <Typography variant="h6">
                  {records?.chamber_of_commerce_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Full Name</Typography>
                <Typography variant="h6">{records?.full_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Gender</Typography>
                <Typography variant="h6">{records?.gender}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">ID Type</Typography>
                <Typography variant="h6">{records?.id_type}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Industry</Typography>
                <Typography variant="h6">{records?.industry}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Nationality</Typography>
                <Typography variant="h6">{records?.nationality}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Phone Number</Typography>
                <Typography variant="h6">{records?.phone_number}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Residential City</Typography>
                <Typography variant="h6">
                  {records?.residential_city}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Residential Country</Typography>
                <Typography variant="h6">
                  {records?.residential_country}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Residential Postal Code</Typography>
                <Typography variant="h6">
                  {records?.residential_postal_code}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Residential State</Typography>
                <Typography variant="h6">
                  {records?.residential_state}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Residential Street Address</Typography>
                <Typography variant="h6">
                  {records?.residential_street_address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Swift/BIC Code</Typography>
                <Typography variant="h6">{records?.swift_bic_code}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Politically Exposed Person</Typography>
                <Typography variant="h6">{records?.pp_accepted}</Typography>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Gov ID Back Image</Typography>
                <a href={records?.gov_id_back_image_url} target="_blank">
                  Download Gov ID Back
                </a>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Gov ID Front Image</Typography>

                <a href={records?.gov_id_front_image_url} target="_blank">
                  Download Gov ID Front
                </a>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Tax Id Number Url</Typography>

                <a href={records?.tax_id_number_url} target="_blank">
                  Download Tax ID Number
                </a>
              </Grid>
              <Grid item xs={12} sm={12 / 3}>
                <Typography variant="h6">Politically Exposed Person</Typography>
                <Typography variant="h6">{records?.pep_person}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default SpOrganizerBusinessInfo;

const thLabels = [
  "Payment Method Id",
  "Title",
  "Type",
  "Image",
  "Fees Percentage",
  "Active",
  "Action",
];
