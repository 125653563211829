import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  InputAdornment,
  TextField,
  InputBase,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

import {
  InputField,
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";
import { Visibility } from "@mui/icons-material";
import Utils from "../../../utils/utils";
import { LoadingButton } from "@mui/lab";

const SpAllEvents = () => {
  usePageTitle("All Events");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        let { data } = await ApiManager(
          "get",
          `superAdmin/all-events?per_page=${rowsPerPage}&page=${page}`,
        );
        setRecords(data?.response?.detail);
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  const handleStatus = async (e, item, key) => {
    let _fd = {
      [key]: e.target.checked ? 1 : 0,
    };
    try {
      dispatch(handleLoader(true));

      // setRecords((prev) => ({
      //   ...prev,
      //   data: prev?.data?.map((obj) => {
      //     if (obj.event_id === item.event_id) {
      //       return { ...obj, [key]: e.target.checked };
      //     }
      //     return obj;
      //   }),
      // }));

      let { data } = await ApiManager(
        "post",
        `superAdmin/update-event-status/${item?.event_id}`,
        _fd,
      );

      setRecords((prev) => ({
        ...prev,
        data: prev?.data?.map((obj) => {
          if (obj.event_id === item.event_id) {
            return data;
          }
          return obj;
        }),
      }));
      showMessage("success", "Event Status Updated");
    } catch (error) {
      // console.log(error);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        All Events
      </Typography>
      <TableWrapper
        spanTd={thLabels.length}
        isLoading={isLoading}
        isContent={records?.data?.length || false}
        thContent={
          <>
            {thLabels.map((v, i) => (
              <TableCell key={i}>{v}</TableCell>
            ))}
          </>
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        perPage={records?.per_page || 0}
        total={records?.total || 0}
        showPagination
      >
        {records?.data?.length &&
          records?.data?.map((item, i) => (
            <RenderRow
              key={i}
              item={item}
              handleStatus={handleStatus}
              setRecords={setRecords}
            />
          ))}
      </TableWrapper>
    </Box>
  );
};

export default SpAllEvents;

const thLabels = [
  "Organizer name",
  "Event name",
  "Location type",
  "Show on Website",
  "Event Published",
  "Service Fees",
  "Action",
];

export const RenderRow = React.memo(({ item, setRecords, handleStatus }) => {
  const [serviceFee, setServiceFee] = useState("");
  const [feesLoading, setFeesLoading] = useState(false);

  const handleServiceFees = async (item) => {
    if (!serviceFee.trim()) {
      return;
    }
    try {
      setFeesLoading(true);
      let { data } = await ApiManager(
        "post",
        `superAdmin/update-feeses/${item?.event_id}`,
        { service_fees: serviceFee },
      );

      // showMessage("success", "Event Fees  Updated")
    } catch (error) {
      console.log(error);
    } finally {
      setFeesLoading(false);
    }
  };

  const handleToggle = () => {
    setRecords((prev) => ({
      ...prev,
      data: prev.data.map((obj) => ({
        ...obj,
        isEdit: obj.event_id === item.event_id,
      })),
    }));
  };

  return (
    <>
      <StyledTableRow key={item?.event_id}>
        <StyledTableCell>{item?.organizer_name || "-"}</StyledTableCell>
        <StyledTableCell>{item?.name}</StyledTableCell>
        <StyledTableCell>
          {item?.location_type === "to_be_announced"
            ? "To be announced"
            : Utils.capitalize(item?.location_type)}
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Switch
                checked={item?.show_on_website}
                onChange={(e) => handleStatus(e, item, "show_on_website")}
              />
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <FormControlLabel
            control={
              <Switch
                checked={item?.event_published}
                onChange={(e) => handleStatus(e, item, "event_published")}
              />
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          {!item?.isEdit && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {item?.service_fees_share}
              <IconButton color="info" onClick={handleToggle}>
                <EditIcon />
              </IconButton>
            </Stack>
          )}
          <Stack direction="row">
            {item?.isEdit && (
              <>
                <InputField
                  key={item.event_id}
                  onChange={(e) => setServiceFee(e.target.value)}
                  value={serviceFee}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LoadingButton
                          loading={feesLoading}
                          onClick={() => handleServiceFees(item)}
                        >
                          Update
                        </LoadingButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </Stack>
        </StyledTableCell>

        <StyledTableCell>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Tooltip title="View event" placement="top">
              <IconButton
                color="info"
                component="a"
                target="_blank"
                href={`https://cxevents.global/events/event-details/${item?.event_id}`}
              >
                {/* target="_blank" href=
                      {`https://cxevents.global/events/event-details/${item?.event_id}`} */}

                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="View event on Admin Panel!" placement="top">
              <IconButton
                color="info"
                component="a"
                target="_blank"
                href={`https://cxevents.global/organizations/events/${item?.event_id}/dashboard/home`}
              >
                {/* target="_blank" href=
                      {`https://cxevents.global/events/event-details/${item?.event_id}`} */}

                <Visibility />
              </IconButton>
            </Tooltip>
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
});
