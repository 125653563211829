import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { useDispatch } from "react-redux";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpEventTypes = () => {
  usePageTitle("Event Types");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager("get", "superAdmin/event-types");
      if (!!data?.response?.detail?.length > 0) {
        setRecords(data?.response?.detail);
      }
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatus = async (e, item) => {
    setRecords((prev) =>
      prev.map((obj) => {
        if (obj.event_type_id === item.event_type_id) {
          return { ...obj, active: e.target.checked };
        }
        return obj;
      }),
    );
    let _fd = {
      _method: "patch",
      status: e.target.checked ? "active" : "not-active",
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager(
        "post",
        `superAdmin/event-types/${item?.event_type_id}`,
        _fd,
      );
      showMessage("success", "Record status updated successfully.");
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = ["ID", "Title", "Status", "Actions"];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Event Types
        </Typography>
        <Stack direction="row" m={2}>
          <Button
            onClick={() => navigate("/admin/event-types/create-event-type")}
            startIcon={<AddIcon />}
          >
            Add Event Type
          </Button>
        </Stack>

        <TableWrapper
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.event_type_id}</StyledTableCell>
              <StyledTableCell>{item?.title}</StyledTableCell>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item?.active}
                      onChange={(e) => handleStatus(e, item)}
                    />
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/admin/event-types/edit-event-type/${item?.event_type_id}`,
                        )
                      }
                    >
                      <CreateRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default SpEventTypes;
