import React from "react";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  Tooltip,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const FilePicker = ({
  onChange: handleImage,
  imageUrl,
  helperText,
  labelTop,
  error: propsError,
  accept = ".jpg,.jpeg,.png",
  boxWidth = "100px",
  boxHeight = "56px",
  boxMinHeight = "",
  boxMaxHeight = "auto",
  alt = "logo.png",
  title: propsTitle,
  _id = "file-input",
}) => {
  return (
    <>
      {labelTop && (
        <InputLabel
          sx={{
            marginBottom: "5px",
            color: "#000",
          }}
        >
          {labelTop}
        </InputLabel>
      )}
      <input
        type="file"
        hidden
        id={_id}
        onChange={handleImage}
        accept={accept}
      />
      <Tooltip title={propsTitle} placement="top" arrow>
        <Button
          variant="text"
          sx={{
            position: "relative",
            width: boxWidth,
            height: boxHeight,
            minHeight: !imageUrl ? boxMinHeight : "auto",
            maxHeight: boxMaxHeight,
            p: 0,
          }}
          onClick={() => {}}
        >
          <Box
            component="label"
            htmlFor={_id}
            sx={{
              cursor: "pointer",
              width: boxWidth,
              height: boxHeight,
              maxHeight: boxMaxHeight,
              minHeight: !imageUrl ? boxMinHeight : "auto",
              backgroundColor: "#ccc",
              overflow: "hidden",
            }}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                width={boxWidth}
                height={boxHeight}
                // maxHeight={boxMaxHeight}
                alt={alt}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: "#ffffffa3",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                children={<FileUploadIcon sx={{ color: "#ccc" }} />}
              />
            )}
          </Box>
        </Button>
      </Tooltip>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {propsError && (
        <FormHelperText sx={{ color: "red", mt: "0 !important" }}>
          {propsError}
        </FormHelperText>
      )}
    </>
  );
};

export default FilePicker;
