import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Grid,
  Box,
  Typography,
  MenuItem,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EventIcon from "@mui/icons-material/Event";
import { Link, useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import Groups2Icon from "@mui/icons-material/Groups2";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

import useAnimateNumber from "../../hooks/use-animate-number";
import ComingSoon from "../../component/coming-soon/coming-soon";
// import { InputDate } from '../../component';
import ChartPanel from "./chart-panel/chart-panel";
import ApiManager from "../../services/api-manager";
import { BarChart } from "@mui/x-charts";
import _ from "lodash";

const Home = () => {
  const { user } = useSelector((state) => state.storeReducer);
  const navigate = useNavigate();
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreStatsLoading, setIsMoreStatsLoading] = useState(false);
  const [moreStats, setMoreStats] = useState(null);
  const [records, setRecords] = useState({});
  const [currencyArray, setCurrencyArray] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  //  manage data for graph
  const salesValuesForGraph = moreStats?.sales?.length
    ? _?.map(moreStats?.sales, "amount")?.map((value) => Number(value))
    : [0];

  const tipValuesForGraph = _?.map(moreStats?.tips, "tip")?.map((value) =>
    Number(value),
  );
  const topupsValuesForGraph = _?.map(moreStats?.top_ups, "total")?.map(
    (value) => Number(value),
  );
  const dateForGraph = _?.map(moreStats?.sales, "date");

  useEffect(() => {
    if (selectedCurrency !== null) {
      getCurrencyBasedStats();
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (user?.role === "super_admin") {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager("get", `${_role}/stats`);
      if (data?.currencies_data.length) {
        let createData = data?.currencies_data.map((item) => ({
          id: item?.currency_id,
          name: item?.currency_name,
          symbol: item?.currency_symbol,
        }));
        setCurrencyArray(createData);
        setSelectedCurrency(createData[0].id);
      }
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrencyBasedStats = async () => {
    try {
      setIsMoreStatsLoading(true);
      let { data } = await ApiManager(
        "post",
        `${_role}/stats-with-currencies/${selectedCurrency}`,
      );
      setMoreStats(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsMoreStatsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.role !== "super_admin") {
      navigate("/events");
    }
    if (user?.role === "partner") {
      navigate("/partner/organizer");
    }
  }, [user?.role]);

  if (user?.role !== "super_admin") {
    return <></>;
  }

  return (
    <Container maxWidth="100%">
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent="flex-end">
          <Button variant="contained" sx={{ color: "white" }} onClick={getData}>
            Refresh
          </Button>
        </Grid>
        {_isAdmin && (
          <Grid item md={4} sm={6} xs={12}>
            <Box
              component={Link}
              to="/admin/organizer"
              sx={{ textDecoration: "none" }}
            >
              <Item
                title="Event Organizers"
                icon={<Groups2Icon sx={{ fontSize: 36 }} />}
                value={records?.organizers || 0}
                key={isLoading}
              />
            </Box>
          </Grid>
        )}
        <Grid item md={4} sm={6} xs={12}>
          <Item
            title="Events"
            icon={<EventIcon sx={{ fontSize: 36 }} />}
            value={records?.events || 0}
            key={isLoading}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Item
            title="Orders"
            icon={<MonetizationOnIcon sx={{ fontSize: 36 }} />}
            value={records?.orders || 0}
            key={isLoading}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Item
            title="Vendors"
            icon={<Groups2Icon sx={{ fontSize: 36 }} />}
            value={records?.vendors || 0}
            key={isLoading}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Box
            component={Link}
            to="/admin/currency"
            sx={{ textDecoration: "none" }}
          >
            <Item
              title="Currencies"
              icon={<LocalAtmIcon sx={{ fontSize: 36 }} />}
              value={records?.currencies_data?.length || 0}
              key={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
      {true && (
        <Grid mt={4} container spacing={2}>
          <Grid item md={9} xs={12} sx={{ order: { md: 1, xs: 2 } }}>
            <Box sx={styles.bg}>
              <Typography mb={2} sx={{ fontSize: 18, fontWeight: 600 }}>
                Statistical Summary
              </Typography>
              {/* <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Box maxWidth="180px">
                <InputDate label="From" error="" value="" onChange={(date) => console.log(date)} />
              </Box>
              <Box maxWidth="180px">
                <InputDate label="To" error="" value="" onChange={(date) => console.log(date)} />
              </Box>
            </Stack> */}

              <Box mt={2}>
                {isMoreStatsLoading ? (
                  <Stack alignItems="center">
                    <CircularProgress size={26} />
                  </Stack>
                ) : (
                  <ChartPanel data={moreStats} />
                  // <BarChart
                  //   xAxis={[{ scaleType: "band", data: dateForGraph }]}
                  //   series={[
                  //     {
                  //       data: salesValuesForGraph,
                  //       stack: "A",
                  //       label: "Sales",
                  //     },
                  //     {
                  //       data: tipValuesForGraph,
                  //       stack: "B",
                  //       label: "Tips",
                  //     },
                  //     {
                  //       data: topupsValuesForGraph,
                  //       stack: "C",
                  //       label: "TopUps",
                  //     },
                  //   ]}
                  //   height={400}
                  // />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={3} xs={12} sx={{ order: { md: 2, xs: 1 } }}>
            <Box sx={styles.bg}>
              <Typography mb={2} sx={{ fontSize: 18, fontWeight: 600 }}>
                Currencies
              </Typography>
              {isLoading ? (
                <Stack alignItems="center">
                  <CircularProgress size={26} />
                </Stack>
              ) : (
                <Box>
                  {currencyArray.map((item, index) => {
                    return (
                      <MenuItem
                        selected={selectedCurrency === item?.id}
                        onClick={() => setSelectedCurrency(item?.id)}
                        key={index}
                      >
                        <CircleIcon sx={{ fontSize: 8 }} />
                        &nbsp;&nbsp;&nbsp;{item?.symbol} ({item?.name})
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const Item = ({ value, title, icon, sign = "" }) => {
  const number = useAnimateNumber(value);

  return (
    <Box sx={styles.card}>
      {icon}
      <Typography sx={styles.title}>{title}</Typography>
      <Typography sx={styles.numbers}>
        {sign}
        {number}
      </Typography>
    </Box>
  );
};

const styles = {
  card: {
    bgcolor: "#fff",
    px: 2,
    py: 3,
    color: "#333",
    borderRadius: 2,
    boxShadow: "0 10px 10px #00000017",
    transition: "0.2s all ease-in-out",
    ":hover": {
      bgcolor: "primary.main",
      color: "#fff",
    },
  },
  bg: {
    bgcolor: "#fff",
    px: 2,
    py: 3,
    color: "#333",
    borderRadius: 2,
    boxShadow: "0 10px 10px #00000017",
  },
  title: {
    fontSize: { md: 24, xs: 18 },
    fontWeight: 500,
    fontFamily: "Work Sans",
  },
  numbers: {
    fontSize: { md: 32, xs: 22 },
    fontWeight: 600,
    textAlign: "end",
  },
};

export default Home;
