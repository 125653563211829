import moment from "moment";

export default class Utils {
  static generateId() {
    return Math.ceil(Math.random() * 10000000);
  }

  static matchPassword(pass, c_pass) {
    if (!!pass && c_pass !== pass) {
      return true;
    }
    return false;
  }

  static isValidEmail(value) {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    if (regex.test(value)) {
      return true;
    }
    return false;
  }

  static isNumber(str) {
    if (/^[0-9\b]+$/.test(str)) {
      return true;
    }
    return false;
  }

  static hasOnlyNonNumericCharacters(inputString) {
    const regex = /^[^0-9]+$/;
    if (regex.test(inputString)) {
      return true;
    }
    return false;
  }

  static inRange(value, max) {
    if (value.length < max) {
      return true;
    }
    return false;
  }

  static handleBandID(str, value) {
    if (value.length < str.length) {
      return str
        .replace(/[^\da-zA-Z]/g, "")
        .replace(/(.{2})/g, "$1:")
        .trim();
    }
    return str;
  }

  static capitalize(str) {
    if (!!str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return "";
  }

  static removeUnderscore(str) {
    if (!!str) {
      return str.replace(/_/g, " ");
    }
    return "";
  }

  static formatWithSuffix(number = 0) {
    let num = Number(number);

    if (num % 1 !== 0 && num < 1000) {
      num = num.toString();
      const decimalIndex = num.indexOf(".");
      if (decimalIndex !== -1) {
        return (num = num.slice(0, decimalIndex + 2)); // Extract only two decimal places
      }
    }

    if (num >= 1000000) {
      num = num / 1000000;
      num = num.toString();
      console.log(num);
      const decimalIndex = num.indexOf(".");
      if (decimalIndex !== -1) {
        num = num.slice(0, decimalIndex + 3);
      }
      return num + "M";
    } else if (num >= 1000) {
      num = num / 1000;
      num = num.toString();
      const decimalIndex = num.indexOf(".");
      if (decimalIndex !== -1) {
        num = num.slice(0, decimalIndex + 3);
      }
      return num + "K";
    }
    return num;
  }

  static formatDate(date) {
    if (!date) {
      return "-";
    }
    return moment(date).format(process.env.REACT_APP_SERVER_FORMAT);
  }

  static formatDateInHours(dateString) {
    if (!dateString) {
      return "-";
    }
    return moment(dateString).format("HH:mm:ss");
  }

  static limitText(text, maxLength) {
    const str = String(text);
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.substring(0, maxLength - 3) + "...";
    }
  }
}
