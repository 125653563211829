import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { InputDate, InputField } from "../../../../../component";
import usePageTitle from "../../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../../store/reducer";
import ApiManager from "../../../../../services/api-manager";
import errorsSetter from "../../../../../helpers/error-setter";

const dateFormat = "YYYY-MM-DD";

const AddCategory = () => {
  const { id, categoryID = null } = useParams();
  usePageTitle(`${categoryID ? "Update" : "Add"} Category`);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({
    allow_manual_items: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent } = useSelector((state) => state.storeReducer);
  const _symbol = loggedEvent.currencySymbol;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const _path = `/event/${id}/management/cashless/category`;

  useEffect(() => {
    async function init() {
      if (categoryID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/product-categories/${categoryID}`,
          );
          setFormData(data);
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [categoryID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.title.length < 3) {
      setFormErrors({ title: "Name must be at least 3 character long " });
      return;
    }
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let _url = categoryID
        ? `organizer/product-categories/${categoryID}`
        : "organizer/product-categories";

      const _fd = {
        ...formData,
        event_id: id,
      };

      if (categoryID) {
        _fd._method = "patch";
      }
      await ApiManager("post", _url, _fd);
      if (categoryID) {
        showMessage("success", "Category updated successfully.");
      } else {
        showMessage("success", "Category created successfully.");
      }
      navigate(_path);
    } catch (error) {
      console.log(
        "🚀 ~ file: add-category.js:84 ~ handleSubmit ~ error:",
        error,
      );
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          autoCapitalize="off"
        >
          <Typography variant="h5">Add Category</Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item sm={6} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="title"
                  required
                  error={formErrors?.title}
                  value={formData?.title}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box>
                <InputField
                  label="Order num"
                  name="order_num"
                  required
                  type="number"
                  error={formErrors?.order_num}
                  value={formData?.order_num}
                  onChange={handleInputChange}
                  inputProps={{
                    min: 1,
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default AddCategory;
