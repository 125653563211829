import { Box } from "@mui/material";
import React from "react";

import { AdminDrawer, AdminHeader } from "../../component";
import { adminRoutes, organizerRoutes, partnerRoutes } from "./data";
import { useSelector } from "react-redux";

const PrimaryLayout = ({ children, layout }) => {
  const { user } = useSelector((state) => state.storeReducer);
  const role = user?.role;
  const roleBasedRoutes = {
    super_admin: adminRoutes,
    organizer: organizerRoutes,
    partner: partnerRoutes,
  };

  return (
    <Box>
      <AdminHeader />

      <Box
        sx={{
          width: "100%",
          display: "flex",
        }}
      >
        <AdminDrawer
          // routes={role === "super_admin" ? adminRoutes : organizerRoutes}
          routes={roleBasedRoutes[role]}
          flag={true}
        />

        <Box
          component={"main"}
          sx={{
            width: { md: "calc(100% - 260px)", xs: "100%" },
            padding: "16px",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default PrimaryLayout;
