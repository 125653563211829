import ComingSoon from "../component/coming-soon/coming-soon";
import {
  Attendee,
  CreateEvent,
  CreateUser,
  EventUserManagement,
  Events,
  Overview,
  About,
  AccessControlPoint,
  CreateAccessPoint,
  Login,
  ForgotPassword,
  ResetPassword,
  AccessPointSetting,
  TicketType,
  CreateTicketType,
  EventTickets,
  TicketInfo,
  EditTicket,
  LeadCaptureSetting,
  Vendor,
  AddVendor,
  Product,
  AddProducts,
  SpMerchandise,
  SpCreateMerchandise,
  SpOrganizer,
  SpCreateOrganizer,
  SpCurrency,
  SpCreateCurrency,
  AttendeeReports,
  AttendeeTopupReport,
  AttendeeSpendingReport,
  AdmittedReports,
  RevenueReports,
  RevenueReportDetail,
  InPersonTopUpReport,
  VendorReport,
  SpEvents,
  VendorReportDetails,
  VendorLogs,
  Home,
  SpVenue,
  SpCreateVenue,
  Cost,
  VendorDetails,
  AddCost,
  SpContact,
  SpViewEmail,
  TicketReports,
  VendorOrderDetail,
  EventMerchandise,
  CreateEventMerchandise,
  MerchandiseReports,
  MerchandiseOrderDetail,
  SpBlogs,
  CreateBlog,
  DiscountVouchers,
  CreateDiscountVouchers,
  OnlinePersonTopUpReport,
  VouchersReports,
  VouchersReportDetail,
  SpDemoRequests,
  SpDownloadPresentationRequests,
  SpViewDemoRequest,
  SpViewPresentationRequest,
  CreateTestCard,
  GetOrdersAgainstCard,
  GetTopUpAgainstCard,
  OrdersDetails,
  GetTotalAmount,
  GetReportsOfCardAndCash,
  SpBtaConfig,
  Category,
  AddCategory,
  RefundsReports,
  TermsAndConditions,
  StaffReports,
  Settings,
  SpEventCategories,
  SpEventTypes,
  SpCreateEventType,
  SpCreateEventCategory,
  SpHomePageBanner,
  SpAddHomePageBanner,
  SpOrdersDetails,
  SpOrderAllDetails,
  SpAwaitingForPublish,
  SpAwaitingForPublishDetails,
  SpPublishedEvent,
  SpUpdateHomeBanner,
  TopUpMethods,
  CreateTopsMethods,
  SpPaymentMethods,
  SpAddPaymentMethods,
  SpEmailIllustrations,
  SpFaqs,
  SpCreateFaqs,
  SpPayoutAndTaxes,
  SpPayoutDetail,
  SpPartner,
  SpCreatePartner,
  Profile,
  PtEventStatistics,
  SpOrganizerBusinessInfo,
  SpAllEvents,
} from "../screens";
import TestCard from "../screens/events/test-card/test-card";

export const publicRoutes = [
  {
    path: "/login",
    component: <Login />,
    afterAuthAllowed: false,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
    afterAuthAllowed: false,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
    afterAuthAllowed: false,
  },
  {
    path: "/vendor/stats/:id",
    component: <VendorDetails />,
    afterAuthAllowed: true,
  },

  {
    path: "/terms-and-conditions",
    component: <TermsAndConditions />,
    afterAuthAllowed: true,
  },
];

export const protectedRoutes = [
  {
    path: "/",
    component: <Home />,
    layoutType: "primary",
    type: "all",
  },
  {
    path: "/profile",
    component: <Profile />,
    layoutType: "primary",
    type: "all",
  },
  {
    path: "/admin/merchandise",
    component: <SpMerchandise />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/merchandise/create",
    component: <SpCreateMerchandise />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/merchandise/update/:id",
    component: <SpCreateMerchandise />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/organizer",
    component: <SpOrganizer />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/partner/organizer",
    component: <SpOrganizer />,
    layoutType: "primary",
    type: "partner",
  },
  {
    path: "/partner/organizer/create",
    component: <SpCreateOrganizer />,
    layoutType: "primary",
    type: "partner",
  },
  {
    path: "/partner/organizer/update/:id",
    component: <SpCreateOrganizer />,
    layoutType: "primary",
    type: "partner",
  },
  {
    path: "/partner/organizer/:id/events",
    component: <SpEvents />,
    layoutType: "primary",
    type: "partner",
  },
  {
    path: "/event-statistics",
    component: <PtEventStatistics />,
    layoutType: "primary",
    type: "partner",
  },
  {
    path: "/admin/organizer/create",
    component: <SpCreateOrganizer />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/organizer/:id/business-info",
    component: <SpOrganizerBusinessInfo />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/organizer/update/:id",
    component: <SpCreateOrganizer />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/organizer/:id/events",
    component: <SpEvents />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/currency",
    component: <SpCurrency />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/currency/add",
    component: <SpCreateCurrency />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/currency/update/:id",
    component: <SpCreateCurrency />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/contact",
    component: <SpContact />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/contact/:id/view",
    component: <SpViewEmail />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/venue",
    component: <SpVenue />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/venue/add",
    component: <SpCreateVenue />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/venue/update/:id",
    component: <SpCreateVenue />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/blogs",
    component: <SpBlogs />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/blogs/create-blog",
    component: <CreateBlog />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/blogs/edit-blog/:blogId",
    component: <CreateBlog />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-categories",
    component: <SpEventCategories />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-categories/create-event-category",
    component: <SpCreateEventCategory />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-categories/create-event-category/:id",
    component: <SpCreateEventCategory />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-properties/all-events",
    component: <SpAllEvents />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-properties/awaiting-for-publish",
    component: <SpAwaitingForPublish />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-properties/awaiting-for-publish/details/:awaitingId",
    component: <SpAwaitingForPublishDetails />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-properties/published-events",
    component: <SpPublishedEvent />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-properties/faqs",
    component: <SpFaqs />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-properties/create-faqs",
    component: <SpCreateFaqs />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-properties/update-faq/:id",
    component: <SpCreateFaqs />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/home-page-banners",
    component: <SpHomePageBanner />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/payout-and-taxes",
    component: <SpPayoutAndTaxes />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/payout-detail/:id",
    component: <SpPayoutDetail />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/home-page-banners/update-home-banners/:eventId",
    component: <SpUpdateHomeBanner />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/home-page-banners/add-home-banners",
    component: <SpAddHomePageBanner />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/email-illustrations",
    component: <SpEmailIllustrations />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-types",
    component: <SpEventTypes />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-types/create-event-type",
    component: <SpCreateEventType />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/event-types/edit-event-type/:id",
    component: <SpCreateEventType />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/demo-requests",
    component: <SpDemoRequests />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/demo-requests/:demoID/view",
    component: <SpViewDemoRequest />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/download-presentation-requests",
    component: <SpDownloadPresentationRequests />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/download-presentation-requests/:presentationID/view",
    component: <SpViewPresentationRequest />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/fees",
    component: <SpBtaConfig />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/payment-methods",
    component: <SpPaymentMethods />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/add-payment-methods",
    component: <SpAddPaymentMethods />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/update-payment-methods/:id",
    component: <SpAddPaymentMethods />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/tickets/tickets-orders",
    component: <SpOrdersDetails />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/tickets/tickets-orders/details/:orderId",
    component: <SpOrderAllDetails />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/partner",
    component: <SpPartner />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/partner/create-partner",
    component: <SpCreatePartner />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/admin/partner/update-partner/:id",
    component: <SpCreatePartner />,
    layoutType: "primary",
    type: "super_admin",
  },
  {
    path: "/events",
    component: <Events />,
    layoutType: "primary",
    type: "organizer",
  },
  {
    path: "/about",
    component: <About />,
    layoutType: "primary",
    type: "organizer",
  },
  {
    path: "/events/create-event",
    component: <CreateEvent />,
    layoutType: "primary",
    type: "organizer",
  },
  {
    path: "/events/update-event/:id",
    component: <CreateEvent />,
    layoutType: "primary",
    type: "organizer",
  },
  {
    path: "/event/:id/management/overview",
    component: <Overview />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/attendee",
    component: <Attendee />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/user-management",
    component: <EventUserManagement />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/user-management/get-total-amount/:deviceID",
    component: <GetTotalAmount />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/user-management/get-reports-amount/:deviceID",
    component: <GetReportsOfCardAndCash />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/user-management/create-user",
    component: <CreateUser />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/user-management/update-user/:userID",
    component: <CreateUser />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/merchandise",
    component: <EventMerchandise />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/merchandise/add",
    component: <CreateEventMerchandise />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/merchandise/:merchandiseID/update",
    component: <CreateEventMerchandise />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/test-card",
    component: <TestCard />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/test-card/create-test-card",
    component: <CreateTestCard />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/test-card/get-order-against-card/:orderId",
    component: <GetOrdersAgainstCard />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/test-card/get-order-against-card/:orderId/order-details",
    component: <OrdersDetails />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/test-card/get-topup-against-card/:orderId",
    component: <GetTopUpAgainstCard />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/top-up-methods",
    component: <TopUpMethods />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/top-up-methods/create",
    component: <CreateTopsMethods />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/top-up-methods/update/:topupMethodId",
    component: <CreateTopsMethods />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/access-control-points",
    component: <AccessControlPoint />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/discount-vouchers",
    component: <DiscountVouchers />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/discount-vouchers/create-voucher",
    component: <CreateDiscountVouchers />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/discount-vouchers/edit-voucher/:voucherID",
    component: <CreateDiscountVouchers />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/access-control-points/create",
    component: <CreateAccessPoint />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/access-control-points/update/:accessPointId",
    component: <CreateAccessPoint />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/access-control-points/setting/:accessPointId",
    component: <AccessPointSetting />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/ticket-types",
    component: <TicketType />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/ticket-type/create",
    component: <CreateTicketType />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/ticket-type/update/:ticketID",
    component: <CreateTicketType />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/tickets",
    component: <EventTickets />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/tickets/ticket-info/:ticketID",
    component: <TicketInfo />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/tickets/create",
    component: <EditTicket />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/access-control/tickets/edit/:ticketID",
    component: <EditTicket />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/attendee-report",
    component: <AttendeeReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/attendee-topup-report/:ticketID",
    component: <AttendeeTopupReport />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/attendee-spending-report/:ticketID",
    component: <AttendeeSpendingReport />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/revenue-report",
    component: <RevenueReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/revenue-report/:orderID",
    component: <RevenueReportDetail />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/vouchers-report",
    component: <VouchersReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/vouchers-report/:voucherID",
    component: <VouchersReportDetail />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/admitted-report",
    component: <AdmittedReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/ticket-report",
    component: <TicketReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/merchandise-report",
    component: <MerchandiseReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/merchandise-report/:orderID/details",
    component: <MerchandiseOrderDetail />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/in-person-topup-report",
    component: <InPersonTopUpReport />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/staff-credits-report",
    component: <StaffReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/online-topup-report",
    component: <OnlinePersonTopUpReport />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/vendor-report",
    component: <VendorReport />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/vendor-report/:vendorID",
    component: <VendorReportDetails />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/vendor-report/:vendorID/:orderID",
    component: <VendorOrderDetail />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/setting",
    component: <LeadCaptureSetting />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/lead-capture/refunds-reports",
    component: <RefundsReports />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/:vendorID/logs",
    component: <VendorLogs />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors",
    component: <Vendor />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/create",
    component: <AddVendor />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/update/:vendorID",
    component: <AddVendor />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/manage/:vendorID/product",
    component: <Product />,
    layoutType: "secondary",
    type: "all",
  },

  {
    path: "/event/:id/management/cashless/vendors/manage/:vendorID/product/create",
    component: <AddProducts />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/manage/:vendorID/product/update/:productID",
    component: <AddProducts />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/manage/:vendorID/cost",
    component: <Cost />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/manage/:vendorID/cost/create",
    component: <AddCost />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/vendors/manage/:vendorID/cost/update/:costID",
    component: <AddCost />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/category",
    component: <Category />,
    layoutType: "secondary",
    type: "all",
  },

  {
    path: "/event/:id/management/cashless/category/create",
    component: <AddCategory />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/management/cashless/category/update/:categoryID",
    component: <AddCategory />,
    layoutType: "secondary",
    type: "all",
  },
  {
    path: "/event/:id/settings",
    component: <Settings />,
    layoutType: "secondary",
    type: "all",
  },
];
