import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Checkbox,
  ClickAwayListener,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function ExportBtn({ data = [], path = "", ...props }) {
  const [open, setOpen] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);

  React.useEffect(() => {
    setCheckedValues(data);
  }, [data]);

  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  const exportCSV = () => {
    const params = new URLSearchParams();

    checkedValues.forEach((value) => {
      params.append("columns[]", value);
    });

    const queryString = params.toString();
    window.open(
      `${process.env.REACT_APP_API_KEY + path + queryString}`,
      "_blank",
    );
    handleClose();
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          handleClose();
        }
      }}
    >
      <Box position="relative">
        <Button type="button" onClick={handleOpen} {...props}>
          <FileDownloadIcon />
          Export to CSV
        </Button>
        <Box sx={styles.container(open)} component={Paper}>
          <Box sx={{ minWidth: 200 }}>
            {data.length ? (
              <FormGroup sx={{ px: 2 }}>
                {data.map((label, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={checkedValues.includes(label)}
                          onChange={(e) => {
                            if (checkedValues.includes(label)) {
                              setCheckedValues((prev) =>
                                prev.filter((value) => value !== label),
                              );
                            } else {
                              setCheckedValues((prev) => [...prev, label]);
                            }
                          }}
                        />
                      }
                      label={label}
                    />
                  );
                })}
              </FormGroup>
            ) : null}
            <Button fullWidth disabled={!data?.length} onClick={exportCSV}>
              Export
            </Button>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

const styles = {
  container: (open) => {
    return {
      position: "absolute",
      top: 45,
      left: 0,
      display: open ? "block" : "none",
      zIndex: 999,
    };
  },
};

export default ExportBtn;
