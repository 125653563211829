import React, { useEffect, useState } from "react";
import ManageLayout from "../manage-layout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  TableCell,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import UploadIcon from "@mui/icons-material/Upload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  ExportBtn,
  SelectBox,
  StartAndEndDateFilter,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../../../component";
import { handleLoader, setToast } from "../../../../../../store/reducer";
import ApiManager from "../../../../../../services/api-manager";
import usePageTitle from "../../../../../../hooks/use-page-title";
import Utils from "../../../../../../utils/utils";

// TODO: csv link 404

const Product = () => {
  const { id, vendorID } = useParams();
  usePageTitle("Product");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [category, setCategory] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent, user } = useSelector((state) => state.storeReducer);
  const _symbol = loggedEvent.currencySymbol;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const [columns, setColumns] = useState([]);

  const [filtersState, setFiltersState] = useState({
    start_date: "",
    end_date: "",
  });

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, filtersState.start_date, filtersState.end_date]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const getVendorProduct = ApiManager(
        "get",
        `${_role}/vendor-products?page=${page}&per_page=${rowsPerPage}&vendor_id=${vendorID}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}`,
      );
      const getCategory = ApiManager(
        "get",
        `${_role}/get-categories?event_id=${id}`,
      );
      const apiData = await Promise.all([getVendorProduct, getCategory]);
      setCategory(
        apiData[1].data.map((each) => ({
          label: each.title,
          value: each.category_id,
        })),
      );
      setRecords(apiData[0]?.data?.vendorProducts);
      setColumns(apiData[0].data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCsvUpload = async (event) => {
    if (!event.target.files.length) {
      return;
    }
    let ext = event.target.files[0].name.split(".");
    if (ext[1] !== "csv") {
      return dispatch(
        setToast({
          type: "error",
          message: "Please select only .csv extension file.",
        }),
      );
    }
    try {
      dispatch(handleLoader(true));
      let _fd = new FormData();
      _fd.append("file", event.target.files[0]);
      _fd.append("event_id", id);
      _fd.append("vendor_id ", vendorID);

      let { data } = await ApiManager(
        "post",
        `organizer/vp/upload-csv?vendor_id=${vendorID}`,
        _fd,
        { "Content-Type": "multipart/form-data" },
      );
      showMessage("success", data);
      if (page === 1) {
        getData();
      } else {
        setPage(1);
      }
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleStatus = async (e, item, index) => {
    setRecords((prev) => ({
      ...prev,
      data: prev?.data.map((obj) => {
        if (obj.product_id === item.product_id) {
          return { ...obj, active: e.target.checked };
        }
        return obj;
      }),
    }));
    let _fd = {
      _method: "patch",
      active: e.target.checked,
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager(
        "post",
        `organizer/vendor-products/${item?.product_id}`,
        _fd,
      );
      showMessage("success", "Status updated successfully.");
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleCategory = async (value, item) => {
    setRecords((prev) => ({
      ...prev,
      data: prev?.data.map((obj) => {
        if (obj.product_id === item.product_id) {
          return {
            ...obj,
            product_category: { ...obj.product_category, category_id: value },
          };
        }
        return obj;
      }),
    }));

    try {
      await ApiManager(
        "get",
        `organizer/update-product-category-only/${item?.product_id}/${value}`,
      );
      showMessage("success", "Category updated successfully.");
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    }
  };

  const thLabels = [
    "Category",
    "Name",
    "Order number",
    "Price",
    "Total Sales",
    "Include Alcohol",
    "Status",
  ];

  if (!_isAdmin) {
    thLabels.push("Manage");
  }

  return (
    <div>
      <ManageLayout>
        {/* <Stack direction={{ sm: "row", xs: "column" }} my={2}>
          {!_isAdmin && (
            <>
              <Button onClick={() => navigate(`/event/${id}/management/cashless/vendors/manage/${vendorID}/product/create`)} startIcon={<AddIcon />}>
                Add Product
              </Button>
              <Button startIcon={<UploadIcon />} onClick={() => document.getElementById("myFileInput").click()}>
                File Upload
              </Button>
              <input
                type="file"
                id="myFileInput"
                accept=".csv"
                style={{
                  display: "none",
                }}
                onChange={handleCsvUpload}
              />
            </>
          )}
          <ExportBtn path={`reports-event-vendor-products-csv?event_id=${id}&vendor_id=${vendorID}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&`} data={columns} />
        </Stack> */}

        <Grid container spacing={2} mb={2}>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            <Stack direction="row">
              {!_isAdmin && (
                <>
                  <Button
                    onClick={() =>
                      navigate(
                        `/event/${id}/management/cashless/vendors/manage/${vendorID}/product/create`,
                      )
                    }
                    startIcon={<AddIcon />}
                  >
                    Add Product
                  </Button>
                  <Button
                    startIcon={<UploadIcon />}
                    onClick={() =>
                      document.getElementById("myFileInput").click()
                    }
                  >
                    File Upload
                  </Button>
                  <a
                    href={`${process.env.REACT_APP_SAMPLE_API_KEY}download-product-import-sample-file`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button startIcon={<UploadIcon />}>
                      Sample File Download
                    </Button>
                  </a>
                  <a
                    href={`${process.env.REACT_APP_SAMPLE_API_KEY}vendor-products-sales-report/${id}/${vendorID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button startIcon={<FileDownloadIcon />}>
                      Export to XLSX
                    </Button>
                  </a>
                  <input
                    type="file"
                    id="myFileInput"
                    accept=".csv"
                    style={{
                      display: "none",
                    }}
                    onChange={handleCsvUpload}
                  />
                </>
              )}
              <ExportBtn
                path={`reports-event-vendor-products-csv?event_id=${id}&vendor_id=${vendorID}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&`}
                data={columns}
              />
            </Stack>
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                }))
              }
            />
          </Grid>
        </Grid>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>
                <SelectBox
                  items={category}
                  value={item?.product_category?.category_id}
                  onChange={({ target }) => handleCategory(target.value, item)}
                />
              </StyledTableCell>
              <StyledTableCell>{item?.name}</StyledTableCell>
              <StyledTableCell>{item?.order_number}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.price}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.sales)}
              </StyledTableCell>
              <StyledTableCell>
                {item?.include_alcohol ? "Yes" : "No"}
              </StyledTableCell>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item?.active}
                      disabled={_isAdmin}
                      onChange={(e) => handleStatus(e, item, i)}
                    />
                  }
                />
              </StyledTableCell>
              {!_isAdmin && (
                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        color="info"
                        onClick={() =>
                          navigate(
                            `/event/${id}/management/cashless/vendors/manage/${vendorID}/product/update/${item?.product_id}`,
                          )
                        }
                      >
                        <CreateRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableWrapper>
      </ManageLayout>
    </div>
  );
};

export default Product;
